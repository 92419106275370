<template>
  <div ref="scroller" class="calendar__chart-calendar">
    <div class="calendar__chart-calendar-inner" :style="{ width: totalWidth + 'px' }">
      <div class="calendar__chart-calendar-row" :style="{ transform: `translateX(${scrollLeft}px)` }">
        <div class="calendar__chart-calendar-months">
          <div
            v-for="month in monthsItems"
            :key="month.id"
            class="calendar__chart-calendar-month text-body-1 font-weight-medium py-3"
            :style="{ width: month.width + 'px' }"
          >
            <div class="ms-4">{{ month.label }}</div>
          </div>
        </div>
        <div class="calendar__chart-calendar-dates">
          <div
            v-for="(date, index) in items"
            :key="date.id"
            class="calendar__chart-calendar-date grey--text"
            :style="{
              width: index === 0 ? firstWidth + 'px' : index === items.length - 1 ? lastWidth + 'px' : cellWidth + 'px',
            }"
          >
            <div class="calendar__chart-calendar-date-week text-caption">{{ date.week }}</div>
            <div v-if="!date.isToday" class="calendar__chart-calendar-date-day text-body-1 font-weight-medium">
              {{ date.day }}
            </div>
            <div
              v-else
              ref="isTodayDate"
              class="calendar__chart-calendar-date-day text-body-1 font-weight-medium --is-today"
            >
              {{ date.day }}
            </div>
          </div>
        </div>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
          :style="{ width: viewportWidth + 'px' }"
        ></v-progress-linear>
      </div>
      <!-- <div ref="todayLine" class="today-line"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarChartCalendar',

  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    monthsItems: {
      type: Array,
      default: () => [],
    },
    cellWidth: {
      type: Number,
      default: 32.65,
    },
    viewportHeight: {
      type: Number,
      default: 0,
    },
    viewportWidth: {
      type: Number,
      default: 0,
    },
    totalWidth: {
      type: Number,
      default: 0,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    scrollLeft: {
      type: Number,
      default: 0,
    },
    currentDate: {
      type: Date,
      default: () => new Date(),
    },

    firstWidth: {
      type: Number,
      required: true,
    },
    lastWidth: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {};
  },

  watch: {
    currentDate() {
      this.$nextTick(() => this.setTodayLine());
    },

    scrollLeft(value) {
      if (value === this.$refs.scroller.scrollLeft) return;
      this.$nextTick(() => {
        this.$refs.scroller.scrollLeft = value;
        this.setTodayLine();
      });
    },

    viewportWidth() {
      if (!this.$refs.isTodayDate) return;

      this.setTodayLine();
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setTodayLine();
    });
  },

  methods: {
    setTodayLine() {
      // if (!this.$refs.isTodayDate || !this.$refs.isTodayDate[0]) {
      //   this.$refs.todayLine.style.display = 'none';
      //   return;
      // }
      // this.$refs.todayLine.style.display = 'block';
      // const { left, top, width, height } = this.$refs.isTodayDate[0].getBoundingClientRect();
      // const { left: maxLeft, width: timeLineWidth } = this.$parent.$refs.timeline.$el.getBoundingClientRect();
      // const maxRight = maxLeft + timeLineWidth;
      // const leftPos = left + width / 2;
      // if (leftPos < maxLeft || leftPos > maxRight) {
      //   this.$refs.todayLine.style.display = 'none';
      //   return;
      // }
      // this.$refs.todayLine.style.left = `${leftPos}px`;
      // this.$refs.todayLine.style.top = `${top + height}px`;
      // this.$refs.todayLine.style.height = `${this.viewportHeight + 11}px`;
    },
  },
};
</script>

<style lang="scss">
.calendar {
  &__chart-calendar {
    height: 116px;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__chart-calendar-inner {
    overflow: hidden;

    .today-line {
      position: fixed;
      top: 105px;
      left: 0;
      right: 0;
      width: 1px;
      background: #002fff;
      z-index: 5;
      pointer-events: none;
    }
  }

  &__chart-calendar-row {
    overflow: hidden;
  }

  &__chart-calendar-months {
    display: flex;
    overflow: hidden;
    height: 48px;
    width: 100%;
  }

  &__chart-calendar-month {
    border-bottom: 1px solid #e6ebff;
    overflow: hidden;
    white-space: nowrap;
    &:not(:last-child) {
      border-right: 1px solid #e6ebff;
    }
  }

  &__chart-calendar-dates {
    height: 68px;
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__chart-calendar-month-dates {
    overflow: hidden;
    display: flex;
    width: 100%;
  }

  &__chart-calendar-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #e6ebff;
    border-bottom: 1px solid #e6ebff;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
  }

  &__chart-calendar-date-day {
    height: 24px;
    width: 24px;
    border-radius: 2px;

    &.--is-today {
      background: #002fff;
      color: #ffffff;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #002fff;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 12px;
        background: #002fff;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  .v-progress-linear {
    position: absolute;
    bottom: 0;
  }
}
</style>
