<template>
  <multi-select
    v-model="modelWrapperWithoutNull"
    :loading="isLoading"
    :items="items"
    :all-message="allMessage"
    :item-text="itemText"
    :item-value="itemValue"
    :can-select-all="canSelectAll"
    :search="search"
    :label="label"
    :is-filtered="isFiltered"
    :error-messages="errorMessages"
    :hidden-search="hiddenSearch"
    v-bind="$attrs"
    @update:search="setSearch"
    v-on="inheritedListeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
    <template #append-item>
      <div v-intersect="endIntersect" />
    </template>
  </multi-select>
</template>

<script>
// http
import client from '@/http/client';

// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';
import { debounce } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';

// Components
import MultiSelect from '@/components/MultiSelect.vue';

export default {
  name: 'SchemaFormMultiSelect',

  components: { MultiSelect },

  inheritAttrs: true,

  props: {
    value: { type: Object, default: () => ({}) },
    label: { type: String, default: '' },
    payload: { type: Object, default: () => ({}) },
    queryItems: { type: Function, default: null },
    allMessage: { type: String, default: '' },
    canSelectAll: { type: Boolean, default: true },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    itemText: { type: [String, Function], default: undefined },
    itemValue: { type: [String, Function], default: undefined },
    isFiltered: { type: Boolean, default: false },
    hiddenSearch: { type: Boolean, default: false },
  },

  data() {
    return {
      items: [],
      total: null,
      search: null,
      isLoading: false,
      debouncedFetchItems: null,
      foundItems: [],
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    modelWrapperWithoutNull: {
      get() {
        return this.modelWrapper || {};
      },

      set(value) {
        this.modelWrapper = value;
      },
    },
  },

  watch: {
    modelWrapperWithoutNull() {
      this.$emit('validate');
    },
  },

  created() {
    this.debouncedFetchItems = debounce(this.fetchNewItems);
    this.fetchItems().then(results => {
      this.items = results;
    });
  },

  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.total > this.items.length) {
        this.fetchItems({ offset: this.items.length }).then(results => {
          this.items = [...this.items, ...results];
        });
      }
    },

    async fetchItems({ search = this.search, offset = 0 } = {}) {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequest = cancelSource.cancel;

      this.isLoading = true;

      return this.queryItems({ search, offset }, { cancelToken: cancelSource.token })
        .then(({ count, results }) => {
          this.total = count;
          return results;
        })
        .finally(() => {
          this.$options.cancelRequest = null;
          this.isLoading = false;
        });
    },

    async fetchNewItems({ search = this.search } = {}) {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequest = cancelSource.cancel;

      this.isLoading = true;

      this.queryItems({ search }, { cancelToken: cancelSource.token })
        .then(({ count, results }) => {
          this.total = count;
          this.items = [];
          this.$nextTick(() => {
            this.items = results;
          });
        })
        .finally(() => {
          this.$options.cancelRequest = null;
          this.isLoading = false;
        });
    },

    setSearch(search) {
      this.search = search;
      this.debouncedFetchItems();
    },
  },

  cancelRequest: null,
};
</script>
