<template>
  <v-dialog v-model="showModal" width="560">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" elevation="0" color="light-grey" large class="grey--text" v-on="on">
        <span>{{ $t('button.archive') }}</span>
        <v-icon>mdi-archive</v-icon>
      </v-btn>
    </template>

    <v-card content-class="base-modal__card">
      <v-card-title class="pb-8 px-4 pt-4 px-md-6 pt-md-6 align-start justify-space-between flex-nowrap">
        <h1 class="text-h6 text-md-h5 font-weight-bold">
          {{ $t(label) }}
        </h1>

        <v-btn icon color="primary" @click="showModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-0">
        <v-list class="py-0">
          <div class="multi-select__search-field">
            <text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="multi-select__search mt-0 pt-0 mb-2"
              hide-details
              :placeholder="$t('label.search')"
              @input="setSearch"
            />
          </div>
          <v-hover v-for="item in items" v-slot="{ hover }" :key="item.id">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-text="item[itemText]"></v-list-item-title>
              </v-list-item-content>
              <v-btn
                v-if="canUpdateRequests"
                icon
                color="primary"
                :style="{ opacity: media.isMobile || hover ? 1 : 0 }"
                @click="unarchiveItem(item)"
              >
                <v-icon>mdi-archive-arrow-up</v-icon>
              </v-btn>
            </v-list-item>
          </v-hover>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// http
import client from '@/http/client';

// components
import TextField from '@/components/TextField.vue';

import { debounce } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';

export default {
  name: 'ModalArchivedItems',

  components: {
    TextField,
  },

  inject: ['media'],

  props: {
    label: {
      type: String,
      default: 'label.archive',
    },
    itemText: {
      type: String,
      default: 'name',
    },
    queryItems: {
      type: Function,
      required: true,
    },
    unarchiveItemRequest: {
      type: Function,
      required: true,
    },
    canUpdateRequests: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      items: [],
      search: null,
      isLoading: false,
      debouncedFetchItems: null,
      foundItems: [],
    };
  },

  watch: {
    search() {
      this.debouncedFetchItems();
    },
    showModal(isOpen) {
      if (!isOpen) {
        this.search = null;
      }
      this.debouncedFetchItems();
    },
  },

  created() {
    this.debouncedFetchItems = debounce(this.fetchItems, 350);
    this.fetchItems();
  },

  methods: {
    async fetchItems({ search = this.search } = {}) {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequest = cancelSource.cancel;

      this.isLoading = true;

      this.queryItems({ search }, { cancelToken: cancelSource.token })
        .then(items => {
          this.items = items;
        })
        .finally(() => {
          this.$options.cancelRequest = null;
          this.isLoading = false;
        });
    },

    unarchiveItem(item) {
      this.unarchiveItemRequest(item.id);
      this.debouncedFetchItems();
    },

    setSearch(search) {
      this.search = search;
      this.debouncedFetchItems();
    },
  },
};
</script>
