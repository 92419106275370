<template>
  <form-multi-select
    ref="multiselect"
    v-model="modelWrapper"
    :label="label"
    :error-messages="errors"
    :query-items="queryItems"
    :item-text="getTranslatedText"
    :all-message="$t('label.all_services')"
    :item-value="item => item"
    :value-comparator="$options.compareById"
    :is-filtered="isFiltered"
    hidden-search
    v-on="inheritedListeners"
  />
</template>

<script>
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchManyServiceTypes } from '@/services/select';

// Components
import FormMultiSelect from './FormMultiselect.vue';

export default {
  name: 'SchemaFormFieldManyServiceTypes',

  components: { FormMultiSelect },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    isFiltered() {
      return Boolean(this.payload.clients);
    },
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.multiselect?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems() {
      return fetchManyServiceTypes().then(({ count, results }) => {
        return {
          count,
          results: results.map(item => ({
            id: item.value,
            name: item.name,
          })),
        };
      });
    },

    getTranslatedText(item) {
      return this.$t(item.name);
    },
  },

  compareById,
};
</script>
