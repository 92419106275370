<template>
  <form-multi-select
    ref="multiselect"
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    :item-text="item => `${item.name}`"
    :translate="true"
    :all-message="`All payment type`"
    :item-value="item => item"
    :value-comparator="$options.compareById"
    :key-value="'value'"
    hidden-search
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchManyPaymentType } from '@/services/select';

// Components
import FormMultiSelect from './FormMultiselect.vue';

export default {
  name: 'SchemaFormFieldPaymentType',

  components: { FormMultiSelect },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },

  methods: {
    queryItems(payload, config) {
      return fetchManyPaymentType({}, config).then(({ count, results }) => {
        results.push({
          name: 'payment_type.application',
          value: 'application',
        });
        return { count, results };
      });
    },
  },

  compareById,
};
</script>
