<template>
  <multi-select
    v-model="modelWrapperWithoutNull"
    :loading="isLoading"
    :items="items"
    :all-message="$t('label.all_types')"
    item-text="name"
    :item-value="item => item"
    :can-select-all="canSelectAll"
    :search="search"
    :label="label"
    :menu-max-height="457"
    v-bind="$attrs"
    @update:search="setSearch"
    v-on="inheritedListeners"
  >
    <template #content="{ item }">
      <v-hover v-slot="{ hover }">
        <div class="d-flex justify-space-between align-center">
          {{ item.name }}
          <div v-if="!isArchivePage && canUpdateRequests" class="d-flex">
            <v-btn
              icon
              color="primary"
              :style="{ opacity: media.isMobile || hover ? 1 : 0 }"
              @click="routeToEditType(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
              :style="{ opacity: media.isMobile || hover ? 1 : 0 }"
              @click.stop="archiveType(item)"
            >
              <v-icon>mdi-archive-arrow-down</v-icon>
            </v-btn>
          </div>
        </div>
      </v-hover>
    </template>
    <template v-if="!isArchivePage" #footer>
      <div class="d-flex flex-wrap align-center justify-space-between py-2 px-4">
        <v-btn v-if="canUpdateRequests" text color="primary" class="addTypeButton" @click="routeToCreateType">
          <v-icon class="me-4">mdi-plus</v-icon>
          <span v-if="media.isMobile">{{ $t('button.add') }}</span>
          <span v-else>{{ $t('button.add_type') }}</span>
        </v-btn>
        <modal-archived-items
          label="requests.type_archive"
          :items="archiveItems"
          :query-items="queryArchiveItems"
          :unarchive-item-request="unarchiveType"
          :can-update-requests="canUpdateRequests"
          item-text="name"
        />
      </div>
    </template>
  </multi-select>
</template>

<script>
// http
import client from '@/http/client';

// Utils
import { isEqual } from '@/utils/common';
import { debounce } from '@/utils/delay';
import { compareById } from '@/utils/comparators';
import { flushPromises } from '@/utils/scheduler';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Constants
import { REQUESTS_TYPE_CREATE, REQUESTS_TYPE_EDIT, REQUESTS_ARCHIVE } from '@/constants/routes';
import * as actions from '@/constants/actions';
import * as subjects from '@/constants/subjects';

// Services
import requestsService from '@/services/requests';
import { fetchManyRequestsTypes, fetchRequestsTypes } from '@/services/select';

import ModalArchivedItems from '@/components/ModalArchivedItems.vue';
import MultiSelect from '@/components/MultiSelect.vue';

export default {
  compareById,

  name: 'SchemaFormFieldManyRequestType',

  components: {
    MultiSelect,
    ModalArchivedItems,
  },

  inject: ['media'],

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      items: [],
      archiveItems: [],
      search: null,
      isLoading: false,
      canSelectAll: false,
      archivedTypes: [],
      searchArchiveTypes: '',
      debouncedFetchItems: null,
      isArchivePage: this.$route.name === REQUESTS_ARCHIVE,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    modelWrapperWithoutNull: {
      get() {
        return this.modelWrapper || {};
      },

      set(value) {
        this.modelWrapper = value;
      },
    },
    localDisabled() {
      return this.payload?.disabled || this.disabled;
    },
    canUpdateRequests() {
      return this.$can(actions.UPDATE, subjects.REQUESTS);
    },
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.debouncedFetchItems();
      }
    },

    // eslint-disable-next-line
    '$route.name': function(routeName) {
      this.isArchivePage = routeName === REQUESTS_ARCHIVE;
      this.fetchItems();
    },
  },

  created() {
    this.debouncedFetchItems = debounce(this.fetchItems, 300);
    this.fetchItems();
  },

  methods: {
    async fetchItems() {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequest = cancelSource.cancel;

      this.isLoading = true;

      return fetchManyRequestsTypes(this.payload, {
        limit: 100,
        search: this.search,
        isArchived: this.isArchivePage ? undefined : false,
      })
        .then(({ results }) => {
          this.canSelectAll = !!results.length;
          this.items = results;
        })
        .finally(() => {
          this.$options.cancelRequest = null;
          this.isLoading = false;
        });
    },

    queryArchiveItems(payload, config) {
      return fetchRequestsTypes(
        {
          limit: 100,
          ...payload,
          isArchived: true,
        },
        config
      ).then(({ results }) => results);
    },

    routeToCreateType() {
      this.$router.push({
        name: REQUESTS_TYPE_CREATE,
        params: { prevPage: this.$route },
      });
    },

    routeToEditType(type) {
      this.$router.push({
        name: REQUESTS_TYPE_EDIT,
        params: {
          id: type.id,
          prevPage: this.$route,
        },
      });
    },

    archiveType(item) {
      requestsService.archiveType(item.id, true).then(() => {
        this.debouncedFetchItems();
      });
    },

    unarchiveType(typeId) {
      requestsService.archiveType(typeId, false).then(() => {
        this.debouncedFetchItems();
      });
    },

    setSearch(search) {
      this.search = search;
      this.debouncedFetchItems();
    },
  },

  cancelRequest: null,
};
</script>

<style lang="scss">
.addTypeButton {
  .v-btn__content {
    justify-content: start;
  }
}
</style>
