<template>
  <div class="schema-view-field-contracts">
    <view-field-contract-container v-if="hasActiveUsages" class="mb-8" :title="$t('unit.contract_active')">
      <component
        :is="component"
        v-for="usage in activeUsages"
        :key="usage.usageId"
        :unit-id="id"
        :is-room="isRoom"
        class="mb-4"
        v-bind="usage"
        @update-usages="updateUsages"
      />
    </view-field-contract-container>

    <view-field-contract-container
      v-if="hasBookedUsages"
      class="mb-8"
      :title="$t('unit.contract_planned')"
      :loading="bookedUsageLoading"
      :with-pagination="!bookedUsagesIsOut"
      @show-more="fetchNextBookedUsages"
    >
      <component
        :is="component"
        v-for="usage in bookedUsages"
        :key="usage.usageId"
        :unit-id="id"
        :is-room="isRoom"
        class="mb-4"
        v-bind="usage"
        @update-usages="updateUsages"
      />
    </view-field-contract-container>

    <view-field-contract-container
      v-if="hasArchivedUsages"
      class="mb-8"
      :title="$t('unit.contract_old')"
      :loading="archivedUsageLoading"
      :with-pagination="!archivedUsagesIsOut"
      @show-more="fetchNextArchivedUsages"
    >
      <component
        :is="component"
        v-for="usage in archivedUsages"
        :key="usage.usageId"
        class="mb-4"
        :unit-id="id"
        :is-room="isRoom"
        v-bind="usage"
        is-archived
      />
    </view-field-contract-container>
  </div>
</template>

<script>
import ViewFieldContractContainer from '@/components/schema/ViewFieldContractContainer.vue';
import usersService, { UsersService } from '@/services/users';
import ViewFieldClientContract from '@/components/schema/ViewFieldClientContract.vue';
import ViewFieldUnitContract from './ViewFieldUnitContract.vue';

const USAGES_LIMIT = 5;

function createUsage() {
  return {
    count: 0,
    offset: 0,
    isLoading: false,
    list: [],
  };
}

export default {
  name: 'SchemaViewFieldContracts',

  components: {
    ViewItem: () => import('./ViewItem.vue'),
    ViewFieldContractContainer,
  },

  props: {
    propPath: { type: Array, required: true },
    payload: { type: Object, required: true },
    isUnit: { type: Boolean, default: false },
    isRoom: { type: Boolean, default: false },
  },

  data() {
    return {
      usages: {
        [UsersService.usagesStatus.ACTIVE]: createUsage(),
        [UsersService.usagesStatus.ARCHIVED]: createUsage(),
        [UsersService.usagesStatus.BOOKED]: createUsage(),
      },
    };
  },

  computed: {
    component() {
      if (this.isUnit || this.isRoom) {
        return ViewFieldUnitContract;
      }

      return ViewFieldClientContract;
    },

    hasActiveUsages() {
      return this.activeUsages.length > 0;
    },

    bookedUsagesIsOut() {
      return (
        this.usages[UsersService.usagesStatus.BOOKED].list.length >= this.usages[UsersService.usagesStatus.BOOKED].count
      );
    },

    archivedUsagesIsOut() {
      return (
        this.usages[UsersService.usagesStatus.ARCHIVED].list.length >=
        this.usages[UsersService.usagesStatus.ARCHIVED].count
      );
    },

    hasArchivedUsages() {
      return this.archivedUsages.length > 0;
    },

    hasBookedUsages() {
      return this.bookedUsages.length > 0;
    },

    activeUsages() {
      return this.usages[UsersService.usagesStatus.ACTIVE].list;
    },

    bookedUsageLoading() {
      return this.usages[UsersService.usagesStatus.ARCHIVED].isLoading;
    },

    archivedUsageLoading() {
      return this.usages[UsersService.usagesStatus.ARCHIVED].isLoading;
    },

    archivedUsages() {
      return this.usages[UsersService.usagesStatus.ARCHIVED].list;
    },

    bookedUsages() {
      return this.usages[UsersService.usagesStatus.BOOKED].list;
    },

    id() {
      return this.payload.usageId;
    },
  },

  mounted() {
    this.payload.roomsUsages?.forEach(roomUsage => {
      switch (roomUsage?.status) {
        case UsersService.usagesStatus.ACTIVE:
          this.setUsage(UsersService.usagesStatus.ACTIVE, { results: [roomUsage] });
          break;
        case UsersService.usagesStatus.ARCHIVED:
          this.setUsage(UsersService.usagesStatus.ARCHIVED, { results: [roomUsage] });
          break;
        case UsersService.usagesStatus.BOOKED:
          this.setUsage(UsersService.usagesStatus.BOOKED, { results: [roomUsage] });
          break;
        default:
          console.warn(`Unknown usage status: ${roomUsage.status}`);
          break;
      }
    });
  },

  async created() {
    const bookedLimit = this.isUnit || this.isRoom ? 1 : 100;

    const [activeUsages, archivedUsages, bookedUsages] = await Promise.all([
      this.fetchUsages({ status: UsersService.usagesStatus.ACTIVE, limit: 100 }),
      this.fetchUsages({ status: UsersService.usagesStatus.ARCHIVED, limit: 1 }),
      this.fetchUsages({ status: UsersService.usagesStatus.BOOKED, limit: bookedLimit }),
    ]);

    this.setUsage(UsersService.usagesStatus.ACTIVE, activeUsages);
    this.setUsage(UsersService.usagesStatus.ARCHIVED, archivedUsages);
    this.setUsage(UsersService.usagesStatus.BOOKED, bookedUsages);
  },

  methods: {
    setUsage(type, usage) {
      this.usages[type].list = [...this.usages[type].list, ...usage.results];
      this.usages[type].count = usage.count;
      this.usages[type].offset += usage.results.length;
    },

    fetchUsages({ status, offset = 0, limit = 0 }) {
      const entityName = this.isUnit || this.isRoom ? 'unit' : 'client';

      const params = { [entityName]: this.id, status, limit: limit || USAGES_LIMIT, offset };

      this.usages[status].isLoading = true;

      return usersService
        .getUsages(params)
        .then(result => {
          const usages = result.results.map(usage => {
            const unit = usage.unit.parentUnit
              ? {
                  id: usage.unit.parentUnit,
                  name: usage.unit.parentUnitName,
                }
              : usage.unit;

            const room = usage.unit.parentUnit
              ? {
                  id: usage.unit.id,
                  name: usage.unit.name,
                }
              : null;

            return {
              ...usage,
              unit,
              room,
            };
          });
          return {
            ...result,
            contractIsModified: true,
            results: usages,
          };
        })
        .finally(() => {
          this.usages[status].isLoading = false;
        });
    },

    fetchNextBookedUsages() {
      return this.fetchNextUsages(UsersService.usagesStatus.BOOKED);
    },

    fetchNextArchivedUsages() {
      return this.fetchNextUsages(UsersService.usagesStatus.ARCHIVED);
    },

    async fetchNextUsages(type) {
      const { offset } = this.usages[type];
      const usages = await this.fetchUsages({ status: type, offset });

      this.setUsage(type, usages);
    },

    async updateUsages() {
      const bookedLimit = this.isUnit || this.isRoom ? 1 : 100;

      const [activeUsages, archivedUsages, bookedUsages] = await Promise.all([
        this.fetchUsages({ status: UsersService.usagesStatus.ACTIVE, limit: 100 }),
        this.fetchUsages({ status: UsersService.usagesStatus.ARCHIVED, limit: 1 }),
        this.fetchUsages({ status: UsersService.usagesStatus.BOOKED, limit: bookedLimit }),
      ]);

      this.usages = {
        [UsersService.usagesStatus.ACTIVE]: createUsage(),
        [UsersService.usagesStatus.ARCHIVED]: createUsage(),
        [UsersService.usagesStatus.BOOKED]: createUsage(),
      };

      this.setUsage(UsersService.usagesStatus.ACTIVE, activeUsages);
      this.setUsage(UsersService.usagesStatus.ARCHIVED, archivedUsages);
      this.setUsage(UsersService.usagesStatus.BOOKED, bookedUsages);
    },
  },
};
</script>
