<template>
  <view-field v-if="currentValue" class="font-weight-medium" :label="label">
    <router-link v-if="accessToRooms && link" :to="link">
      {{ currentValue }}
    </router-link>
    <span v-else>
      {{ currentValue }}
    </span>
  </view-field>
</template>

<script>
import { mapGetters } from 'vuex';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';
import ViewField from '@/components/ViewField.vue';
import { ROOMS_DETAILED } from '@/constants/routes';

export default {
  name: 'SchemaViewFieldRoom',

  components: { ViewField },

  props: {
    value: { type: Object, default: () => ({}) },
    label: { type: String, required: true },
  },

  computed: {
    currentValue() {
      return this.value?.name || '—';
    },

    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },

    link() {
      return this.value?.id ? { name: ROOMS_DETAILED, params: { id: this.value?.id } } : null;
    },
  },
};
</script>
