<template>
  <div v-if="showRow" class="schema-file-item" :class="{ 'schema-file-item--row': showRow }">
    <div class="schema-file-item__info schema-file-item__info--row">
      <p v-if="showInfo" class="schema-file-item__text-name schema-file-item__text-name--row">
        {{ trimFileName(name, 8) }}
      </p>
      <p v-if="size && showInfo" class="schema-file-item__text-size schema-file-item__text-size--row">
        {{ convertSizeFile(size) }}
      </p>
    </div>
    <button v-if="isImage" type="button" @click="click">
      <v-img class="rounded" :src="url" width="80" height="80" />
    </button>
    <a
      v-else-if="!selectable"
      class="schema-file-item__link rounded"
      :class="{ 'schema-file-item--bg-blue-60': !isImage }"
      :href="url"
      :download="name"
    >
      <v-icon :color="showInfo ? 'blue50' : 'blue20'">mdi-text-box</v-icon>
    </a>
    <div
      v-else
      class="schema-file-item__link rounded"
      :class="{ 'schema-file-item--bg-blue-60': !isImage }"
      @click="toggleSelect"
    >
      <v-icon color="blue50">mdi-text-box</v-icon>
    </div>
    <div class="schema-file-item__hover-overlay"></div>
    <div v-if="loading" class="schema-file-item__overlay">
      <v-progress-circular class="schema-file-item__loader" color="white" size="20" width="2" indeterminate />
    </div>

    <button v-else-if="selectable" type="button" class="schema-file-item__select" @click="toggleSelect">
      <v-icon v-if="selected" size="24" color="primary">mdi-radiobox-marked</v-icon>
      <v-icon v-else size="24" color="white">mdi-radiobox-blank</v-icon>
    </button>

    <button v-else-if="editable" type="button" class="schema-file-item__close" @click="remove">
      <v-icon size="16" color="#9E9E9E">mdi-close</v-icon>
    </button>
  </div>

  <div v-else class="d-flex justify-space-between align-center schema-file-item">
    <div class="d-flex align-center">
      <div class="schema-file-item__preview" :class="{ 'schema-file-item--bg-blue-60': !isImage }" rounded>
        <button v-if="isImage" type="button" @click="click">
          <v-img :src="url" width="80" height="80" />
        </button>
        <div v-else-if="!fileIsLoaded && privateUrl" class="schema-file-item__link" @click="downloadFile">
          <div v-if="isLoadingFile">
            <v-progress-circular class="schema-file-item__loader" color="primary" size="30" width="2" indeterminate />
          </div>
          <v-icon v-else color="blue20">mdi-text-box</v-icon>
        </div>
        <a v-else class="schema-file-item__link" :href="url" :download="name">
          <v-icon color="blue20">mdi-text-box</v-icon>
        </a>
        <div class="schema-file-item__hover-overlay"></div>
        <div v-if="loading" class="schema-file-item__overlay">
          <v-progress-circular class="schema-file-item__loader" color="white" size="20" width="2" indeterminate />
        </div>
      </div>
      <div class="ml-2">
        <p class="schema-file-item__text-name">{{ trimFileName(name) }}</p>
        <p v-if="size" class="schema-file-item__text-size">{{ convertSizeFile(size) }}</p>
      </div>
    </div>
    <icon-button v-if="editable" type="button" color="secondary" class="primary--text" @click="remove">
      <v-icon color="blue20">mdi-delete</v-icon>
    </icon-button>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton.vue';

import convertSizeFile from '@/utils/convertSizeFile';
import { middleTruncate } from '@/utils/formatters';

import mediaService from '@/services/media';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';
import { download } from '@/utils/common';

export default {
  name: 'FormSchemaFileItem',
  inject: ['media'],
  components: { IconButton },
  props: {
    url: { type: String, required: true },
    privateUrl: { type: String, default: null },
    loading: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    name: { type: String, default: '' },
    size: { type: Number, default: 0 },
    type: { type: String, default: 'image/' },
    showRow: { type: Boolean, default: false },
    showInfo: { type: Boolean, default: true },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    fileIsLoaded: { type: Boolean, default: true },
  },

  data() {
    return {
      isLoadingFile: false,
    };
  },

  computed: {
    isImage() {
      return this.type.startsWith('image/');
    },
  },

  methods: {
    click() {
      this.$emit('click');
    },

    remove() {
      this.$emit('remove');
    },

    toggleSelect() {
      this.$emit('toggle-select');
    },

    trimFileName(name, customLength) {
      let maxLength = 25;
      if (this.media.isMobile) {
        maxLength = 10;
      }
      if (customLength) {
        maxLength = customLength;
      }
      return middleTruncate(name, { maxLength });
    },

    async downloadFile() {
      this.isLoadingFile = true;
      const { blob } = await this.getPrivateMediaObject(this.privateUrl);
      download(this.name, blob);
      this.isLoadingFile = false;
    },

    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },
    convertSizeFile,
  },
};
</script>

<style lang="scss">
.schema-file-item {
  cursor: pointer;
  &--row {
    width: 80px;
    height: 80px;
    position: relative;
    border: none;
  }

  &__preview {
    width: 80px;
    height: 80px;
    overflow: hidden;
    position: relative;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  &--bg-blue-60 {
    background: $--blue-color-60;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#1e1e22, 0.5);
  }

  &__hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#1e1e22, 0.5);
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &:hover > &__hover-overlay {
    opacity: 1;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__select {
    position: absolute;
    top: 6px;
    right: 6px;
  }
  &__text-name {
    color: $--black-color-0;
    font-weight: $--font-weight-medium;
    font-size: 16px;
    line-height: 28px;
    margin: 0 15px 0 0 !important;
    overflow: hidden;
    white-space: nowrap;
    &--row {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      margin: 20px 0 0 0 !important;
    }
  }
  &__text-size {
    color: $--grey-color-0;
    font-weight: $--font-weight-normal;
    font-size: 14px;
    line-height: 20px;
    margin: 0 !important;
    &--row {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    &--row {
      opacity: 0.5;
    }
  }

  &__info {
    &--row {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      flex-direction: column;
      z-index: 1;
      pointer-events: none;
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    border: 2px solid #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f5f5f5;
    transform: translate(6px, -6px);
  }
}
</style>
