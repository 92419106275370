<template>
  <div ref="calendarList" class="calendar__list">
    <div class="calendar__list-column">
      <div class="calendar__list-empty" />
      <div class="calendar__list-header px-4 py-4">
        <span class="text-h5 font-weight-medium">Properties</span>
      </div>
      <div ref="scroller" class="calendar__list-column-rows">
        <div ref="calendarListRows" class="calendar__list-rows" :style="{ height: totalHeight + 'px' }">
          <div
            class="calendar__list-rows-offset"
            :style="{
              transform: `translateY(${offsetY}px)`,
            }"
          >
            <div
              v-for="unit in items"
              :key="unit.id"
              class="calendar__list-column-row"
              :class="unit.type || 'unit'"
              :style="{ height: cellHeight + 'px' }"
            >
              <div v-if="unit.type === 'room'" class="calendar__list-item text-body-2">
                <div class="text-truncate mx-3 ps-6 py-4">
                  <span class="line"></span>
                  <router-link v-if="accessToRooms" :to="unitLink(unit.id, unit.type)" class="text-decoration-none">{{
                    unit.name
                  }}</router-link>
                  <span v-else>{{ unit.name }}</span>
                </div>
              </div>
              <div v-else class="calendar__list-item text-body-1 font-weight-medium">
                <div class="text-truncate mx-3 py-4">
                  <router-link :to="unitLink(unit.id, unit.type)" class="text-decoration-none">{{
                    unit.name
                  }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Constants
import { UNITS_DETAILED, ROOMS_DETAILED } from '@/constants/routes';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

export default {
  name: 'CalendarList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    cellHeight: {
      type: Number,
      default: 56,
    },
    viewportHeight: {
      type: Number,
      default: 0,
    },
    totalHeight: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
  },

  watch: {
    scrollTop(value) {
      this.$nextTick(() => {
        this.$refs.scroller.scrollTop = value;
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.scroller.scrollTop = this.scrollTop;
    });
  },

  methods: {
    unitLink(unitId, type) {
      return {
        name: type === 'unit' ? UNITS_DETAILED : ROOMS_DETAILED,
        params: { id: unitId, prevPageRoute: { name: this.$route.name } },
      };
    },
  },
};
</script>

<style lang="scss">
.calendar {
  &__list {
    flex: 0 0 207px;
    overflow: hidden;
  }

  &__list-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-right: 1px solid #e6ebff;
  }

  &__list-empty {
    flex: 0 0 48px;
    height: 48px;
    border-bottom: 1px solid #e6ebff;
  }

  &__list-header {
    flex: 0 0 68px;
    height: 68px;
    border-bottom: 1px solid #e6ebff;
  }

  &__list-column-rows {
    flex: 1 1 auto;
    overflow: auto;

    scrollbar-width: 0;

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__list-rows {
    overflow: hidden;
  }

  &__list-rows-offset {
    overflow: hidden;
  }

  &__list-column-row {
    &.unit:not(:first-child) {
      border-top: 1px solid #e6ebff;
    }

    &.unit + .room .line {
      height: 46px;
    }
  }

  &__list-item {
    position: relative;

    a {
      color: #1e1e22;
      transition: color 0.2s;

      &:hover {
        color: #002fff;
      }
    }

    span {
      display: block;
      width: 100%;
    }

    .line {
      height: 70px;
      width: 20px;
      position: absolute;
      left: 12px;
      bottom: 50%;
      border-radius: 0 0 0 15px;
      border-left: 1px solid #e6ebff;
      border-bottom: 1px solid #e6ebff;
    }
  }
}

.scroller {
  position: absolute;
  top: 58px;
  bottom: 14px;
  right: 8px;
  width: 4px;
  background: #eee;
}
.handle {
  position: absolute;
  width: 10px;
  height: 8px;
  top: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  left: -4px;
  background: #fff;
}
</style>
