<template>
  <div class="view-credentials">
    <div class="view-credentials__title">
      <h4>{{ label }}</h4>
    </div>
    <div class="view-credentials__container-credentials">
      <form-field-credentials-control
        v-for="variant in credentialsVariants"
        :key="variant.id"
        :name="variant.name"
        :label="variant.label"
        :with-credentials="variant.withCredentials"
        :value="variant.value"
        :disabled="variant.disabled"
      />
    </div>
  </div>
</template>

<script>
import FormFieldCredentialsControl from './FormFieldCredentialsControl.vue';

const credentialsConfig = {
  dashboard: { label: 'navigation.dashboard', withCredentials: false },
  employees: { label: 'navigation.employees', withCredentials: true },
  invoices: { label: 'navigation.invoice', withCredentials: true },
  projects: { label: 'navigation.projects', withCredentials: true },
  clients: { label: 'navigation.clients', withCredentials: true },
  contracts: { label: 'navigation.contracts', withCredentials: true },
  requests: { label: 'navigation.requests', withCredentials: true },
  issues: { label: 'navigation.task', withCredentials: true },
  services: { label: 'navigation.services', withCredentials: true },
  contractors: { label: 'navigation.contractors', withCredentials: false },
  announcements: { label: 'navigation.announcements', withCredentials: true },
  chat: { label: 'navigation.chat', withCredentials: false },
};

export default {
  name: 'ViewFieldCredentials',
  components: { FormFieldCredentialsControl },
  props: {
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    filtredCredentials() {
      const filtredCredentialsConfig = {};

      Object.entries(credentialsConfig).filter(([name, config]) => {
        let item;
        const value = this.value[name];
        const disabled = true;

        if (value) {
          item = { ...config, name, value, disabled };
          filtredCredentialsConfig[name] = item;
        }

        return item;
      });

      return filtredCredentialsConfig;
    },

    credentialsVariants() {
      return Object.entries(this.filtredCredentials).map(([name, config]) => {
        const value = this.value[name];
        const disabled = true;
        const item = { ...config, name, value, disabled };
        return item;
      });
    },
  },
};
</script>

<style lang="scss">
.view-credentials {
  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
  &__container-credentials {
    max-width: 550px;
  }
  .v-input--selection-controls__input {
    color: #1e1e22;
  }
  .theme--light.v-label--is-disabled {
    color: #1e1e22;
  }
}
</style>
