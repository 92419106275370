<template>
  <v-row no-gutters class="view-field-unit-contract">
    <v-col class="mb-6 mb-md-0" :md="4" :xl="4" :cols="12">
      <view-field :label="periodLabel">
        <contract-period
          :rental-period-end-date="rentalPeriodEndDate"
          :rental-period-start-date="rentalPeriodStartDate"
          :sales-contract-date="salesContractDate"
        />
      </view-field>
    </v-col>

    <v-col :md="room ? 3 : 4" :xl="room ? 3 : 4" :cols="12">
      <view-field-client
        :link-classes="[
          'view-field-unit-contract__client',
          { 'view-field-unit-contract__client--archived': isArchived },
        ]"
        :label="$t('label.client')"
        :value="client"
      />
    </v-col>

    <v-col v-if="room" :md="3" :xl="3" :cols="12">
      <view-field-room
        :unit-id="unitId"
        :link-classes="[
          'view-field-unit-contract__client',
          { 'view-field-unit-contract__client--archived': isArchived },
        ]"
        :label="$t('label.room')"
        :value="room"
      />
    </v-col>

    <div v-if="displayActions" class="view-field-unit-contract__actions d-flex align-center flex-grow-1 justify-end">
      <form-modal
        :title="$t('contracts.edit.edit')"
        :schema="$options.schema"
        :initial-data="initialData"
        :async="true"
        @submit="saveContract"
      >
        <template v-slot:activator="{ on, attrs }">
          <icon-button :width="36" class="mr-2" v-bind="attrs" v-on="on">
            <v-icon>mdi-pencil</v-icon>
          </icon-button>
        </template>
        <template #footer="{ valid }">
          <v-btn
            :disabled="!valid"
            :loading="submitContractLoading"
            type="submit"
            elevation="0"
            color="primary"
            class="mt-2"
          >
            {{ $t('button.edit') }}
          </v-btn>
        </template>
      </form-modal>

      <icon-button :width="36" :loading="isLoading" @click="archive">
        <v-icon>mdi-archive</v-icon>
      </icon-button>
    </div>
  </v-row>
</template>

<script>
import ViewField from '@/components/ViewField.vue';
import ViewFieldClient from '@/components/schema/ViewFieldClient.vue';
import ViewFieldRoom from '@/components/schema/ViewFieldRoom.vue';
import FormModal from '@/components/schema/FormModal.vue';
import { CONTRACTS_DETAILED, UNITS_DETAILED, ROOMS_DETAILED } from '@/constants/routes';
import usersService from '@/services/users';
import viewBuilderService from '@/services/viewBuilder';
import IconButton from '@/components/IconButton.vue';
import ContractPeriod from '@/components/ContractPeriod.vue';
import * as subjects from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

import { schema } from '@/schemas/contract.schema';
import notificationService from '@/services/notification';
import analyticsService from '@/services/analytics';

import { EDIT_CONTRACT } from '@/constants/analyticsActions';

export default {
  name: 'SchemaViewFieldUnitContract',

  components: {
    ViewField,
    ViewFieldClient,
    ViewFieldRoom,
    IconButton,
    ContractPeriod,
    FormModal,
  },

  props: {
    usageId: { type: Number, required: true },
    status: { type: String, required: true },
    rentalPeriodEndDate: { type: [String, Number], default: '' },
    rentalPeriodStartDate: { type: String, default: '' },
    salesContractDate: { type: String, default: '' },
    client: { type: Object, required: true },
    clientType: { type: String, required: true },
    isArchived: { type: Boolean, default: false },
    unitId: { type: Number, required: true },
    project: { type: Object, required: true },
    building: { type: Object, required: true },
    unit: { type: Object, required: true },
    room: { type: Object, default: null },
    isRoom: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      submitContractLoading: false,
    };
  },

  computed: {
    contractRoute() {
      return {
        name: CONTRACTS_DETAILED,
        params: { id: this.usageId },
        query: { pageName: this.isRoom ? ROOMS_DETAILED : UNITS_DETAILED, id: this.unitId },
      };
    },

    displayActions() {
      return !this.isArchived && this.$can(UPDATE, subjects.CONTRACTS);
    },

    periodLabel() {
      if (this.salesContractDate) {
        return this.$t('client.sales_contract_date');
      }

      return this.$t('client.rent');
    },

    initialData() {
      return {
        usageId: this.usageId,
        status: this.status,
        client: this.client,
        clientType: this.clientType,
        project: this.project,
        building: this.building,
        unit: this.unit,
        room: this.room,
        rentalPeriod: `${this.rentalPeriodStartDate} - ${this.rentalPeriodEndDate}` || null,
        salesContractDate: this.salesContractDate,
      };
    },
  },

  methods: {
    archive() {
      this.isLoading = true;

      usersService
        .archiveUsages({
          usages: [this.usageId],
          isArchived: true,
        })
        .then(() => {
          viewBuilderService.notify('archive-usage');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveContract([usage, closeModal]) {
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.submitContractLoading = true;
      usersService
        .updateUsageById(usage, usage.usageId)
        .then(() => {
          const message = this.$t('contracts.contracts_edited');
          notificationService.success(message, 2000);

          analyticsService.track(EDIT_CONTRACT);
          closeModal();
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.values(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.submitContractLoading = false;
          this.$emit('update-usages');
        });
    },
  },

  schema,
};
</script>

<style lang="scss">
.view-field-unit-contract {
  $action-button-width: 36px;
  position: relative;

  &__action {
    width: $action-button-width !important;
    height: $action-button-width !important;
    min-width: $action-button-width !important;
    min-height: $action-button-width !important;
    padding: 6px !important;

    &:not(:hover):not(:focus)::before {
      opacity: 0.08 !important;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      position: initial;
    }
  }

  &__client {
    &--archived {
      opacity: 0.6;
    }
  }
}
</style>
