var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"view-field-unit-contract",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-6 mb-md-0",attrs:{"md":4,"xl":4,"cols":12}},[_c('view-field',{attrs:{"label":_vm.periodLabel}},[_c('contract-period',{attrs:{"rental-period-end-date":_vm.rentalPeriodEndDate,"rental-period-start-date":_vm.rentalPeriodStartDate,"sales-contract-date":_vm.salesContractDate}})],1)],1),_c('v-col',{attrs:{"md":_vm.room ? 3 : 4,"xl":_vm.room ? 3 : 4,"cols":12}},[_c('view-field-client',{attrs:{"link-classes":[
        'view-field-unit-contract__client',
        { 'view-field-unit-contract__client--archived': _vm.isArchived },
      ],"label":_vm.$t('label.client'),"value":_vm.client}})],1),(_vm.room)?_c('v-col',{attrs:{"md":3,"xl":3,"cols":12}},[_c('view-field-room',{attrs:{"unit-id":_vm.unitId,"link-classes":[
        'view-field-unit-contract__client',
        { 'view-field-unit-contract__client--archived': _vm.isArchived },
      ],"label":_vm.$t('label.room'),"value":_vm.room}})],1):_vm._e(),(_vm.displayActions)?_c('div',{staticClass:"view-field-unit-contract__actions d-flex align-center flex-grow-1 justify-end"},[_c('form-modal',{attrs:{"title":_vm.$t('contracts.edit.edit'),"schema":_vm.$options.schema,"initial-data":_vm.initialData,"async":true},on:{"submit":_vm.saveContract},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('icon-button',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"width":36}},'icon-button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"footer",fn:function({ valid }){return [_c('v-btn',{staticClass:"mt-2",attrs:{"disabled":!valid,"loading":_vm.submitContractLoading,"type":"submit","elevation":"0","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('button.edit'))+" ")])]}}],null,false,2230041968)}),_c('icon-button',{attrs:{"width":36,"loading":_vm.isLoading},on:{"click":_vm.archive}},[_c('v-icon',[_vm._v("mdi-archive")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }