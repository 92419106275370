<template>
  <FormAutocomplete
    ref="autocomplete"
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    item-text="name"
    :item-value="item => item"
    :compare-values="$options.compareById"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

import { fetchRequestsTypes } from '@/services/select';
import FormAutocomplete from './FormAutocomplete.vue';

export default {
  name: 'SchemaFormFieldRequestType',

  components: { FormAutocomplete },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.autocomplete?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems(payload) {
      if (payload.search === '') {
        return fetchRequestsTypes({ ...payload, isArchived: false }).then(({ count, results }) => ({ count, results }));
      }
      return fetchRequestsTypes({ ...payload, ...this.payload, isArchived: false }).then(({ count, results }) => ({
        count,
        results,
      }));
    },
  },

  compareById,
};
</script>
