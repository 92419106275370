/* eslint-disable import/prefer-default-export */
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';
import * as onUpdateEffects from './onUpdateEffects';

/**
 * @type {Array<import('./schema').default>}
 */

export const bookingSchema = [
  {
    type: schemaItemTypes.TITLE,
    label: 'label.personal_information',
  },
  {
    type: schemaItemTypes.CLIENT,
    prop: 'client',
    label: 'label.client',
    rules: [rules.REQUIRED],
    showAppendItem: true,
    isCalendarSelect: true,
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'client.passport',
        prop: 'passport',
        size: 2,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.id',
        prop: 'idNumber',
        size: 2,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.COUNTRY,
        label: 'client.country',
        prop: 'country',
        size: 2,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.phone',
        prop: 'phone',
        rules: [rules.PHONE],
      },
    ],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'client.email',
    prop: 'email',
    rules: [rules.REQUIRED, rules.EMAIL],
  },
  {
    type: schemaItemTypes.TITLE,
    label: 'label.address_information',
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.PROJECT,
        label: 'invoice.select_project',
        prop: 'project',
        rules: [rules.REQUIRED],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: 'building',
          },
        ],
      },
      {
        type: schemaItemTypes.BUILDING,
        label: 'invoice.select_building',
        prop: 'building',
        rules: [rules.REQUIRED],
        payload: [{ param: 'projectId', from: ['project', 'id'] }],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: 'project',
            from: 'project',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: 'unit',
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.UNIT,
        label: 'invoice.select_unit',
        prop: 'unit',
        rules: [rules.REQUIRED],
        payload: [
          { param: 'buildingId', from: ['building', 'id'] },
          { param: 'projectId', from: ['project', 'id'] },
          { param: 'roomId', from: ['room', 'id'] },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: 'building',
            from: 'building',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: 'room',
          },
        ],
      },
      {
        type: schemaItemTypes.ROOM,
        label: 'label.room',
        prop: 'room',
        access: ['rooms'],
        payload: [
          { param: 'unitId', from: ['unit', 'id'] },
          { param: 'buildingId', from: ['building', 'id'] },
          { param: 'projectId', from: ['project', 'id'] },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: 'unit',
            from: 'unit',
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.DATE,
    label: 'contracts.edit.rent_period',
    prop: 'rentalPeriod',
    multiple: true,
    tooltip: {
      text: 'contract.period_tooltip',
      icon: 'mdi-information',
    },
    rules: [rules.REQUIRED],
  },
];

export const clientSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'client.first_name',
        prop: 'firstName',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.last_name',
        prop: 'lastName',
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'client.passport',
        prop: 'passport',
        size: 2,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.id',
        prop: 'idNumber',
        size: 2,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.COUNTRY,
        label: 'client.country',
        prop: 'country',
        size: 2,
      },
      {
        type: schemaItemTypes.STRING,
        label: 'client.phone',
        prop: 'phone',
        rules: [rules.PHONE],
      },
    ],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'client.email',
    prop: 'email',
    rules: [rules.REQUIRED, rules.EMAIL],
  },
  {
    type: schemaItemTypes.BOOLEAN,
    prop: 'isSendPasswordWhenCreating',
    label: 'client.change_status_checkbox_send_password_now',
  },
];

export const viewSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'label.name',
        prop: 'client',
        type: schemaItemTypes.CLIENT,
        rules: [rules.REQUIRED],
        size: 6,
      },
      {
        label: 'label.rent_period',
        prop: 'rentalPeriod',
        type: schemaItemTypes.STRING,
        rules: [rules.REQUIRED],
        size: 6,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'label.project',
        prop: 'project',
        type: schemaItemTypes.PROJECT,
        rules: [rules.REQUIRED],
        size: 6,
      },
      {
        label: 'label.building',
        prop: 'building',
        type: schemaItemTypes.BUILDING,
        rules: [rules.REQUIRED],
        size: 6,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'label.unit',
        prop: 'unit',
        type: schemaItemTypes.UNIT,
        rules: [rules.REQUIRED],
        size: 6,
      },
      {
        label: 'label.room',
        prop: 'room',
        type: schemaItemTypes.ROOM,
        size: 6,
      },
    ],
  },
];

export const editSchema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.CLIENT,
        disabled: true,
        prop: 'client',
        label: 'contracts.edit.client',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        disabled: true,
        prop: 'clientType',
        label: 'contracts.edit.client_type',
        rules: [rules.REQUIRED],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'client.project',
        type: schemaItemTypes.PROJECT,
        prop: 'project',
        rules: [rules.REQUIRED],
        onUpdate: [
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: ['building'],
          },
        ],
      },
      {
        type: schemaItemTypes.BUILDING,
        label: 'client.building',
        prop: 'building',
        rules: [rules.REQUIRED],
        payload: [
          {
            param: 'projectId',
            from: ['project', 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: ['project'],
            from: 'project',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: ['unit'],
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.UNIT,
        label: 'client.unit',
        prop: 'unit',
        rules: [rules.REQUIRED],
        payload: [
          {
            param: 'projectId',
            from: ['project', 'id'],
          },
          {
            param: 'buildingId',
            from: ['building', 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: ['building'],
            from: 'building',
          },
          {
            type: onUpdateEffects.RESET_OR_SET_IF_ONE,
            to: ['room'],
          },
        ],
      },
      {
        type: schemaItemTypes.ROOM,
        label: 'label.room',
        prop: 'room',
        access: ['rooms'],
        payload: [
          {
            param: 'unitId',
            from: ['unit', 'id'],
          },
          {
            param: 'buildingId',
            from: ['building', 'id'],
          },
          {
            param: 'projectId',
            from: ['project', 'id'],
          },
        ],
        onUpdate: [
          {
            type: onUpdateEffects.SET,
            to: ['unit'],
            from: 'unit',
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.DATE,
    label: 'contracts.edit.rent_period',
    prop: 'rentalPeriod',
    multiple: true,
    tooltip: {
      text: 'contract.period_tooltip',
      icon: 'mdi-information',
    },
  },
];
