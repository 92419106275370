// Travel Icons
import Rocket from '@/assets/icons/Rocket.svg';
import Plane from '@/assets/icons/Plane.svg';
import Helicopter from '@/assets/icons/Helicopter.svg';
import TrainProfile from '@/assets/icons/TrainProfile.svg';
import SailboatOffshore from '@/assets/icons/SailboatOffshore.svg';
import Harbor from '@/assets/icons/Harbor.svg';
import Car from '@/assets/icons/Car.svg';
import DeliveryTruck from '@/assets/icons/DeliveryTruck.svg';
import Bicycle from '@/assets/icons/Bicycle.svg';
import GasStation from '@/assets/icons/GasStation.svg';
import FuelLine from '@/assets/icons/FuelLine.svg';
import ChargingStation from '@/assets/icons/ChargingStation.svg';
import Earth from '@/assets/icons/Earth.svg';
import Location from '@/assets/icons/Location.svg';
import Enterprise from '@/assets/icons/Enterprise.svg';
import Building from '@/assets/icons/Building.svg';
import Home from '@/assets/icons/Home.svg';
import Hotel from '@/assets/icons/Hotel.svg';
import Store from '@/assets/icons/Store.svg';
import ShoppingCart from '@/assets/icons/ShoppingCart.svg';
import Events from '@/assets/icons/Events.svg';
import UserFollow from '@/assets/icons/UserFollow.svg';
import UserProfileAlt from '@/assets/icons/UserProfileAlt.svg';
import Accessibility from '@/assets/icons/Accessibility.svg';
import Pushchair from '@/assets/icons/Pushchair.svg';
import Pregnant from '@/assets/icons/Pregnant.svg';
import Blind from '@/assets/icons/Blind.svg';
import Rowing from '@/assets/icons/Rowing.svg';
import Customer from '@/assets/icons/Customer.svg';
import DogWalker from '@/assets/icons/DogWalker.svg';
import Education from '@/assets/icons/Education.svg';
import EventsAlt from '@/assets/icons/EventsAlt.svg';
import Construction from '@/assets/icons/Construction.svg';
import PedestrianFamily from '@/assets/icons/PedestrianFamily.svg';
import PedestrianChild from '@/assets/icons/PedestrianChild.svg';
import GenderFemale from '@/assets/icons/GenderFemale.svg';
import GenderMale from '@/assets/icons/GenderMale.svg';
import GroupPresentation from '@/assets/icons/GroupPresentation.svg';
import Incognito from '@/assets/icons/Incognito.svg';
import Meditation from '@/assets/icons/Meditation.svg';
import Finance from '@/assets/icons/Finance.svg';
import ToolBox from '@/assets/icons/ToolBox.svg';
import Wallet from '@/assets/icons/Wallet.svg';
import Money from '@/assets/icons/Money.svg';
import Purchase from '@/assets/icons/Purchase.svg';
import Receipt from '@/assets/icons/Receipt.svg';
import Receipt2 from '@/assets/icons/Receipt2.svg';
import Scales from '@/assets/icons/Scales.svg';
import PiggyBank from '@/assets/icons/PiggyBank.svg';
import Currency from '@/assets/icons/Currency.svg';
import CurrencyBaht from '@/assets/icons/CurrencyBaht.svg';
import CurrencyDollar from '@/assets/icons/CurrencyDollar.svg';
import CurrencyEuro from '@/assets/icons/CurrencyEuro.svg';
import CurrencyRuble from '@/assets/icons/CurrencyRuble.svg';
import Percentage from '@/assets/icons/Percentage.svg';
import PartitionAuto from '@/assets/icons/PartitionAuto.svg';
import Recycle from '@/assets/icons/Recycle.svg';
import B2B from '@/assets/icons/B2B.svg';
import B2C from '@/assets/icons/B2C.svg';
import EngineeringSystems from '@/assets/icons/EngineeringSystems.svg';
import ShareKnowledge from '@/assets/icons/ShareKnowledge.svg';
import Handshake from '@/assets/icons/Handshake.svg';
import Bottles01 from '@/assets/icons/Bottles01.svg';
import Bar from '@/assets/icons/Bar.svg';
import Drink01 from '@/assets/icons/Drink01.svg';
import FruitBowl from '@/assets/icons/FruitBowl.svg';
import NoodleBowl from '@/assets/icons/NoodleBowl.svg';
import Apple from '@/assets/icons/Apple.svg';
import Strawberry from '@/assets/icons/Strawberry.svg';
import Fish from '@/assets/icons/Fish.svg';
import Restaurant from '@/assets/icons/Restaurant.svg';
import ServiceDesk from '@/assets/icons/ServiceDesk.svg';
import Cafe from '@/assets/icons/Cafe.svg';
import Cookie from '@/assets/icons/Cookie.svg';
import Basketball from '@/assets/icons/Basketball.svg';
import Gamification from '@/assets/icons/Gamification.svg';
import ColorPalette from '@/assets/icons/ColorPalette.svg';
import Theater from '@/assets/icons/Theater.svg';
import Music from '@/assets/icons/Music.svg';
import GameConsole from '@/assets/icons/GameConsole.svg';
import Model from '@/assets/icons/Model.svg';
import SprayPaint from '@/assets/icons/SprayPaint.svg';
import Camera from '@/assets/icons/Camera.svg';
import Gift from '@/assets/icons/Gift.svg';
import Gift2 from '@/assets/icons/Gift2.svg';
import Trophy from '@/assets/icons/Trophy.svg';
import CalculationAlt from '@/assets/icons/CalculationAlt.svg';
import Cut from '@/assets/icons/Cut.svg';
import PaintBrush from '@/assets/icons/PaintBrush.svg';
import Paintbrush2 from '@/assets/icons/Paintbrush2.svg';
import PaintRoll from '@/assets/icons/PaintRoll.svg';
import PaintBucket from '@/assets/icons/PaintBucket.svg';
import Broom from '@/assets/icons/Broom.svg';
import Spanner from '@/assets/icons/Spanner.svg';
import Hammer from '@/assets/icons/Hammer.svg';
import ScalesTipped from '@/assets/icons/ScalesTipped.svg';
import CleaningRoom from '@/assets/icons/CleaningRoom.svg';
import Сog from '@/assets/icons/Сog.svg';
import TrashCan from '@/assets/icons/TrashCan.svg';
import Chemistry from '@/assets/icons/Chemistry.svg';
import Hanger from '@/assets/icons/HangingProtocol.svg';

import Bathroom from '@/assets/icons/Bathroom.svg';
import Fridge from '@/assets/icons/Fridge.svg';
import Couch from '@/assets/icons/Couch.svg';
import Armchair from '@/assets/icons/Armchair.svg';
import BarChair from '@/assets/icons/BarChair.svg';
import HotTub from '@/assets/icons/HotTub.svg';
import Shower from '@/assets/icons/Shower.svg';
import Toilet from '@/assets/icons/Toilet.svg';
import ToiletPaper from '@/assets/icons/ToiletPaper.svg';
import ToiletPaperOff from '@/assets/icons/ToiletPaperOff.svg';
import Tv from '@/assets/icons/Tv.svg';
import DoorClose from '@/assets/icons/DoorClose.svg';
import DoorOpen from '@/assets/icons/DoorOpen.svg';
import Speaker from '@/assets/icons/Speaker.svg';
import Vpn from '@/assets/icons/Vpn.svg';
import Wifi from '@/assets/icons/Wifi.svg';
import WifiOff from '@/assets/icons/WifiOff.svg';
import WiFiRouter from '@/assets/icons/WiFiRouter.svg';
import WiFiRouter2 from '@/assets/icons/WiFiRouter2.svg';
import Bed from '@/assets/icons/Bed.svg';
import BedsideTable from '@/assets/icons/BedsideTable.svg';
import BedsideTable2 from '@/assets/icons/BedsideTable2.svg';
import Closet from '@/assets/icons/Closet.svg';
import Chandelier from '@/assets/icons/Chandelier.svg';
import Flashlight from '@/assets/icons/Flashlight.svg';
import Hanger2 from '@/assets/icons/Hanger2.svg';
import Lamp from '@/assets/icons/Lamp.svg';
import WashingMachine from '@/assets/icons/WashingMachine.svg';
import WashingMachine2 from '@/assets/icons/WashingMachine2.svg';
import Weigher from '@/assets/icons/Weigher.svg';
import Shirt from '@/assets/icons/Shirt.svg';

import Binoculars from '@/assets/icons/Binoculars.svg';
import Bullhorn from '@/assets/icons/Bullhorn.svg';
import Calibrate from '@/assets/icons/Calibrate.svg';
import Microscope from '@/assets/icons/Microscope.svg';
import Stethoscope from '@/assets/icons/Stethoscope.svg';
import ToolKit from '@/assets/icons/ToolKit.svg';
import Tools from '@/assets/icons/Tools.svg';
import ToolsAlt from '@/assets/icons/ToolsAlt.svg';
import Clean from '@/assets/icons/Clean.svg';
import Box from '@/assets/icons/Box.svg';
import Email from '@/assets/icons/Email.svg';
import Folder from '@/assets/icons/Folder.svg';
import DocumentBlank from '@/assets/icons/DocumentBlank.svg';
import DocumentMultiple01 from '@/assets/icons/DocumentMultiple01.svg';
import Document from '@/assets/icons/Document.svg';
import DocumentExport from '@/assets/icons/DocumentExport.svg';
import DocumentImport from '@/assets/icons/DocumentImport.svg';
import DocumentTasks from '@/assets/icons/DocumentTasks.svg';
import DocumentSubtract from '@/assets/icons/DocumentSubtract.svg';
import ResultDraft from '@/assets/icons/ResultDraft.svg';
import Report from '@/assets/icons/Report.svg';
import ReportData from '@/assets/icons/ReportData.svg';
import Task from '@/assets/icons/Task.svg';
import TaskComplete from '@/assets/icons/TaskComplete.svg';
import TaskSettings from '@/assets/icons/TaskSettings.svg';
import TaskStar from '@/assets/icons/TaskStar.svg';
import TaskTools from '@/assets/icons/TaskTools.svg';
import TaskView from '@/assets/icons/TaskView.svg';
import LicenseThirdParty from '@/assets/icons/LicenseThirdParty.svg';
import WhitePaper from '@/assets/icons/WhitePaper.svg';
import Policy from '@/assets/icons/Policy.svg';
import LicenseThirdPartyDraft from '@/assets/icons/LicenseThirdPartyDraft.svg';
import CatalogPublish from '@/assets/icons/CatalogPublish.svg';
import ChangeCatalog from '@/assets/icons/ChangeCatalog.svg';
import DocumentAttachment from '@/assets/icons/DocumentAttachment.svg';
import EnvelopeClosed from '@/assets/icons/EnvelopeClosed.svg';
import EnvelopeLetter from '@/assets/icons/EnvelopeLetter.svg';
import EnvelopeOpen from '@/assets/icons/EnvelopeOpen.svg';
import Tag from '@/assets/icons/Tag.svg';
import TagNone from '@/assets/icons/TagNone.svg';
import TagExport from '@/assets/icons/TagExport.svg';
import TagImport from '@/assets/icons/TagImport.svg';
import TagEdit from '@/assets/icons/TagEdit.svg';
import TagGroup from '@/assets/icons/TagGroup.svg';
import ImageIcon from '@/assets/icons/Image.svg';
import ImageIcon2 from '@/assets/icons/Image2.svg';
import ImageCopy from '@/assets/icons/ImageCopy.svg';
import ImageReference from '@/assets/icons/ImageReference.svg';
import ImageSearch from '@/assets/icons/ImageSearch.svg';
import ImageSearchAlt from '@/assets/icons/ImageSearchAlt.svg';
import Attachment from '@/assets/icons/Attachment.svg';
import Pen from '@/assets/icons/Pen.svg';
import PenFountain from '@/assets/icons/PenFountain.svg';
import Stamp from '@/assets/icons/Stamp.svg';
import Ruler from '@/assets/icons/Ruler.svg';
import RulerAlt from '@/assets/icons/RulerAlt.svg';
import Scalpel from '@/assets/icons/Scalpel.svg';
import Filter from '@/assets/icons/Filter.svg';
import Pin from '@/assets/icons/Pin.svg';
import Translate from '@/assets/icons/Translate.svg';
import Book from '@/assets/icons/Book.svg';
import BookBookmark from '@/assets/icons/BookBookmark.svg';
import Sun from '@/assets/icons/Sun.svg';
import Asleep from '@/assets/icons/Asleep.svg';
import Cloud from '@/assets/icons/Cloud.svg';
import Rain from '@/assets/icons/Rain.svg';
import Snow from '@/assets/icons/Snow.svg';
import Umbrella from '@/assets/icons/Umbrella.svg';
import Favorite from '@/assets/icons/Favorite.svg';
import Flash from '@/assets/icons/Flash.svg';
import Star from '@/assets/icons/Star.svg';
import RainDrop from '@/assets/icons/RainDrop.svg';
import Sprout from '@/assets/icons/Sprout.svg';
import Tree from '@/assets/icons/Tree.svg';
import PalmTree from '@/assets/icons/PalmTree.svg';
import Flower from '@/assets/icons/Flower.svg';
import Pest from '@/assets/icons/Pest.svg';
import Debug from '@/assets/icons/Debug.svg';
import Bee from '@/assets/icons/Bee.svg';
import Calendar from '@/assets/icons/Calendar.svg';
import Time from '@/assets/icons/Time.svg';
import Microphone from '@/assets/icons/Microphone.svg';
import Headset from '@/assets/icons/Headset.svg';
import Notification from '@/assets/icons/Notification.svg';
import Phone from '@/assets/icons/Phone.svg';
import WifiController from '@/assets/icons/WifiController.svg';
import HealthCross from '@/assets/icons/HealthCross.svg';
import Chat from '@/assets/icons/Chat.svg';
import ChatBubble from '@/assets/icons/ChatBubble.svg';
import Chat2 from '@/assets/icons/Chat2.svg';
import ChatBubbleText from '@/assets/icons/ChatBubbleText.svg';
import ThumbDown from '@/assets/icons/ThumbDown.svg';
import ThumbUp from '@/assets/icons/ThumbUp.svg';
import Taxi from '@/assets/icons/Taxi.svg';
import HandHeart from '@/assets/icons/HandHeart.svg';
import HandMoney from '@/assets/icons/HandMoney.svg';
import HandPills from '@/assets/icons/HandPills.svg';
import Bookmark from '@/assets/icons/Bookmark.svg';
import Flag from '@/assets/icons/Flag.svg';
import Locked from '@/assets/icons/Locked.svg';
import Unlocked from '@/assets/icons/Unlocked.svg';
import Password from '@/assets/icons/Password.svg';
import Idea from '@/assets/icons/Idea.svg';
import BatteryHalf from '@/assets/icons/BatteryHalf.svg';
import Plug from '@/assets/icons/Plug.svg';
import PlugZap from '@/assets/icons/PlugZap.svg';
import Puzzle from '@/assets/icons/Puzzle.svg';
import Confetti from '@/assets/icons/Confetti.svg';
import Burst from '@/assets/icons/Burst.svg';
import Guitars from '@/assets/icons/Guitars.svg';
import Dumbbells from '@/assets/icons/Dumbbells.svg';
import Cosmetic from '@/assets/icons/Cosmetic.svg';
import Glasses from '@/assets/icons/Glasses.svg';
import DevicePhonePortrait from '@/assets/icons/DevicePhonePortrait.svg';
import DeviceTabletPortrait from '@/assets/icons/DeviceTabletPortrait.svg';
import Locomotive from '@/assets/icons/Locomotive.svg';
import DeliveryTruck2 from '@/assets/icons/DeliveryTruck2.svg';
import DeliveryTruck3 from '@/assets/icons/DeliveryTruck3.svg';
import TrafficIncident from '@/assets/icons/TrafficIncident.svg';
import Car2 from '@/assets/icons/Car2.svg';
import VehicleServices from '@/assets/icons/VehicleServices.svg';
import KickScooter from '@/assets/icons/KickScooter.svg';
import Wheel from '@/assets/icons/Wheel.svg';
import BaggageClaim from '@/assets/icons/BaggageClaim.svg';
import DeliveryParcel from '@/assets/icons/DeliveryParcel.svg';
import Ticket from '@/assets/icons/Ticket.svg';
import NoTicket from '@/assets/icons/NoTicket.svg';
import Crossroads from '@/assets/icons/Crossroads.svg';
import Milestone from '@/assets/icons/Milestone.svg';
import PawPrint from '@/assets/icons/PawPrint.svg';
import Cat from '@/assets/icons/Cat.svg';
import Dog from '@/assets/icons/Dog.svg';
import Bone from '@/assets/icons/Bone.svg';
import MagniferBug from '@/assets/icons/MagniferBug.svg';
import Ufo from '@/assets/icons/Ufo.svg';
import Ufo2 from '@/assets/icons/Ufo2.svg';
import Virus from '@/assets/icons/Virus.svg';
import Home2 from '@/assets/icons/Home2.svg';
import Key from '@/assets/icons/Key.svg';
import TwoKeys from '@/assets/icons/TwoKeys.svg';
import ConnectionReceive from '@/assets/icons/ConnectionReceive.svg';
import ConnectionSend from '@/assets/icons/ConnectionSend.svg';
import ConnectionTwoWay from '@/assets/icons/ConnectionTwoWay.svg';
import Ant from '@/assets/icons/Ant.svg';
import BirthdayCake from '@/assets/icons/BirthdayCake.svg';
import Drink2 from '@/assets/icons/Drink2.svg';
import DrinkAlcohol from '@/assets/icons/DrinkAlcohol.svg';
import Corn from '@/assets/icons/Corn.svg';
import FishMultiple from '@/assets/icons/FishMultiple.svg';
import Bottles2 from '@/assets/icons/Bottles2.svg';
import Wheat from '@/assets/icons/Wheat.svg';
import Fragile from '@/assets/icons/Fragile.svg';
import RestaurantFine from '@/assets/icons/RestaurantFine.svg';
import Mug from '@/assets/icons/Mug.svg';
import MugTea from '@/assets/icons/MugTea.svg';
import Burger from '@/assets/icons/Burger.svg';
import ChefHat from '@/assets/icons/ChefHat.svg';
import Corkscrew from '@/assets/icons/Corkscrew.svg';
import CupPaper from '@/assets/icons/CupPaper.svg';
import Donut from '@/assets/icons/Donut.svg';
import Ladle from '@/assets/icons/Ladle.svg';
import IceCream from '@/assets/icons/IceCream.svg';
import Medication from '@/assets/icons/Medication.svg';
import MedicationAlert from '@/assets/icons/MedicationAlert.svg';
import Pills from '@/assets/icons/Pills.svg';
import PillsAdd from '@/assets/icons/PillsAdd.svg';
import PillsSubtract from '@/assets/icons/PillsSubtract.svg';
import WorshipChristian from '@/assets/icons/WorshipChristian.svg';

export default [
  // Transports
  { name: 'icLocomotive', icon: Locomotive, title: 'Locomotive' },
  { name: 'icRocket', icon: Rocket, title: 'Rocket' },
  { name: 'icPlane', icon: Plane, title: 'Plane' },
  { name: 'icHelicopter', icon: Helicopter, title: 'Helicopter' },
  { name: 'icTrainProfile', icon: TrainProfile, title: 'Train Profile' },
  { name: 'icSailboatOffshore', icon: SailboatOffshore, title: 'Sailboat Offshore' },
  { name: 'icHarbor', icon: Harbor, title: 'Harbor' },
  { name: 'icCar', icon: Car, title: 'Car' },
  { name: 'icCar2', icon: Car2, title: 'Car2' },
  { name: 'icTrafficIncident', icon: TrafficIncident, title: 'Traffic incident' },
  { name: 'icVehicleServices', icon: VehicleServices, title: 'Vehicle services' },
  { name: 'icDeliveryTruck', icon: DeliveryTruck, title: 'Delivery Truck' },
  { name: 'icDeliveryTruck2', icon: DeliveryTruck2, title: 'Delivery Truck 2' },
  { name: 'icDeliveryTruck3', icon: DeliveryTruck3, title: 'Delivery Truck 3' },
  { name: 'icBicycle', icon: Bicycle, title: 'Bicycle' },
  { name: 'icKickScooter', icon: KickScooter, title: 'Kick scooter' },
  { name: 'icWheel', icon: Wheel, title: 'Wheel' },
  { name: 'icBaggageClaim', icon: BaggageClaim, title: 'Baggage claim' },
  { name: 'icDeliveryParcel', icon: DeliveryParcel, title: 'Delivery parcel' },
  { name: 'icTicket', icon: Ticket, title: 'Ticket' },
  { name: 'icNoTicket', icon: NoTicket, title: 'NoTicket' },
  { name: 'icCrossroads', icon: Crossroads, title: 'Crossroads' },
  { name: 'icMilestone', icon: Milestone, title: 'Milestone' },
  { name: 'icGasStation', icon: GasStation, title: 'Gas Station' },
  { name: 'icFuelLine', icon: FuelLine, title: 'Fuel' },
  { name: 'icChargingStation', icon: ChargingStation, title: 'Charging Station' },
  { name: 'icEarth', icon: Earth, title: 'Earth' },
  { name: 'icLocation', icon: Location, title: 'Location' },
  // Animals
  { name: 'icPawPrint', icon: PawPrint, title: 'Paw print' },
  { name: 'icCat', icon: Cat, title: 'Cat' },
  { name: 'icDog', icon: Dog, title: 'Dog' },
  { name: 'icBone', icon: Bone, title: 'Bone' },
  { name: 'icAnt', icon: Ant, title: 'Ant' },
  { name: 'icUfo', icon: Ufo, title: 'Ufo' },
  { name: 'icUfo2', icon: Ufo2, title: 'Ufo 2' },
  { name: 'icDebug', icon: Debug, title: 'Bug' },
  { name: 'icMagniferBug', icon: MagniferBug, title: 'MagniferBug' },
  { name: 'icVirus', icon: Virus, title: 'Virus' },
  // Buildings
  { name: 'icKey', icon: Key, title: 'Key' },
  { name: 'icPassword', icon: Password, title: 'Key 2' },
  { name: 'icTwoKeys', icon: TwoKeys, title: 'Two keys' },
  { name: 'icHome', icon: Home, title: 'Home' },
  { name: 'icHome2', icon: Home2, title: 'Home 2' },
  { name: 'icConnectionReceive', icon: ConnectionReceive, title: 'Connection receive' },
  { name: 'icConnectionSend', icon: ConnectionSend, title: 'Connection send' },
  { name: 'icConnectionTwoWay', icon: ConnectionTwoWay, title: 'Connection two way' },
  { name: 'icEnterprise', icon: Enterprise, title: 'Enterprise' },
  { name: 'icBuilding', icon: Building, title: 'Building' },
  { name: 'icHotel', icon: Hotel, title: 'Hotel' },
  { name: 'icStore', icon: Store, title: 'Store' },
  { name: 'icShoppingCart', icon: ShoppingCart, title: 'Shopping Cart' },
  // People
  { name: 'icEvents', icon: Events, title: 'Events' },
  { name: 'icUserFollow', icon: UserFollow, title: 'User Follow' },
  { name: 'icUserProfileAlt', icon: UserProfileAlt, title: 'User Profile Alt' },
  { name: 'icAccessibility', icon: Accessibility, title: 'Accessibility' },
  { name: 'icPushchair', icon: Pushchair, title: 'Pushchair' },
  { name: 'icPregnant', icon: Pregnant, title: 'Pregnant' },
  { name: 'icBlind', icon: Blind, title: 'Blind' },
  { name: 'icRowing', icon: Rowing, title: 'Rowing' },
  { name: 'icCustomer', icon: Customer, title: 'Customer' },
  { name: 'icDogWalker', icon: DogWalker, title: 'Dog walker' },
  { name: 'icEducation', icon: Education, title: 'Education' },
  { name: 'icEventsAlt', icon: EventsAlt, title: 'Events alt' },
  { name: 'icConstruction', icon: Construction, title: 'Construction' },
  { name: 'icPedestrianFamily', icon: PedestrianFamily, title: 'Pedestrian family' },
  { name: 'icPedestrianChild', icon: PedestrianChild, title: 'Pedestrian child' },
  { name: 'icGenderFemale', icon: GenderFemale, title: 'Gender female' },
  { name: 'icGenderMale', icon: GenderMale, title: 'Gender male' },
  { name: 'icGroupPresentation', icon: GroupPresentation, title: 'Group presentation' },
  { name: 'icIncognito', icon: Incognito, title: 'Incognito' },
  { name: 'icMeditation', icon: Meditation, title: 'Meditation' },
  // Finances
  { name: 'icFinance', icon: Finance, title: 'Finance' },
  { name: 'icToolBox', icon: ToolBox, title: 'Tool Box' },
  { name: 'icWallet', icon: Wallet, title: 'Wallet' },
  { name: 'icMoney', icon: Money, title: 'Money' },
  { name: 'icPurchase', icon: Purchase, title: 'Purchase' },
  { name: 'icReceipt', icon: Receipt, title: 'Receipt' },
  { name: 'icReceipt2', icon: Receipt2, title: 'Receipt2' },
  { name: 'icScales', icon: Scales, title: 'Scales' },
  { name: 'icPiggyBank', icon: PiggyBank, title: 'Piggy Bank' },
  { name: 'icCurrency', icon: Currency, title: 'Currency' },
  { name: 'icCurrencyBaht', icon: CurrencyBaht, title: 'Currency Baht' },
  { name: 'icCurrencyDollar', icon: CurrencyDollar, title: 'Currency Dollar' },
  { name: 'icCurrencyEuro', icon: CurrencyEuro, title: 'Currency Euro' },
  { name: 'icCurrencyRuble', icon: CurrencyRuble, title: 'Currency Ruble' },
  { name: 'icPercentage', icon: Percentage, title: 'Percentage' },
  { name: 'icPartitionAuto', icon: PartitionAuto, title: 'Partition auto' },
  { name: 'icRecycle', icon: Recycle, title: 'Recycle' },
  { name: 'icB2B', icon: B2B, title: 'B2B' },
  { name: 'icB2C', icon: B2C, title: 'B2C' },
  { name: 'icEngineeringSystems', icon: EngineeringSystems, title: 'Engineering systems' },
  { name: 'icShareKnowledge', icon: ShareKnowledge, title: 'Share knowledge' },
  { name: 'icHandshake', icon: Handshake, title: 'Handshake' },
  // Food and drinks
  { name: 'icBottles01', icon: Bottles01, title: 'Bottles' },
  { name: 'icBottles2', icon: Bottles2, title: 'Bottles 2' },
  { name: 'icBar', icon: Bar, title: 'Bar' },
  { name: 'icDrink01', icon: Drink01, title: 'Drink' },
  { name: 'icDrink2', icon: Drink2, title: 'Drink 2' },
  { name: 'icDrinkAlcohol', icon: DrinkAlcohol, title: 'Drink alcohol' },
  { name: 'icMug', icon: Mug, title: 'Mug' },
  { name: 'icMugTea', icon: MugTea, title: 'Mug tea' },
  { name: 'icFruitBowl', icon: FruitBowl, title: 'Fruit Bowl' },
  { name: 'icNoodleBowl', icon: NoodleBowl, title: 'Noodle Bowl' },
  { name: 'icBurger', icon: Burger, title: 'Burger' },
  { name: 'icDonut', icon: Donut, title: 'Donut' },
  { name: 'icIceCream', icon: IceCream, title: 'Ice cream' },
  { name: 'icApple', icon: Apple, title: 'Apple' },
  { name: 'icStrawberry', icon: Strawberry, title: 'Strawberry' },
  { name: 'icCorn', icon: Corn, title: 'Corn' },
  { name: 'icWheat', icon: Wheat, title: 'Wheat' },
  { name: 'icFish', icon: Fish, title: 'Fish' },
  { name: 'icFishMultiple', icon: FishMultiple, title: 'Fish multiple' },
  { name: 'icFragile', icon: Fragile, title: 'Fragile' },
  { name: 'icRestaurant', icon: Restaurant, title: 'Restaurant' },
  { name: 'icRestaurantFine', icon: RestaurantFine, title: 'Restaurant fine' },
  { name: 'icServiceDesk', icon: ServiceDesk, title: 'Service Desk' },
  { name: 'icCafe', icon: Cafe, title: 'Cafe' },
  { name: 'icCookie', icon: Cookie, title: 'Cookie' },
  { name: 'icChefHat', icon: ChefHat, title: 'Chef hat' },
  { name: 'icCorkscrew', icon: Corkscrew, title: 'Corkscrew' },
  { name: 'icCupPaper', icon: CupPaper, title: 'Cup paper' },
  { name: 'icLadle', icon: Ladle, title: 'Ladle' },
  // Entertainment
  { name: 'icBasketball', icon: Basketball, title: 'Basketball' },
  { name: 'icGamification', icon: Gamification, title: 'Gamification' },
  { name: 'icColorPalette', icon: ColorPalette, title: 'Color Palette' },
  { name: 'icTheater', icon: Theater, title: 'Theater' },
  { name: 'icMusic', icon: Music, title: 'Music' },
  { name: 'icGameConsole', icon: GameConsole, title: 'Game Console' },
  { name: 'icModel', icon: Model, title: 'Model' },
  { name: 'icSprayPaint', icon: SprayPaint, title: 'Spray Paint' },
  { name: 'icCamera', icon: Camera, title: 'Camera' },
  { name: 'icGift', icon: Gift, title: 'Gift' },
  { name: 'icGift2', icon: Gift2, title: 'Gift2' },
  { name: 'icTrophy', icon: Trophy, title: 'Trophy' },
  // Interior
  { name: 'icBathroom', icon: Bathroom, title: 'Bathroom' },
  { name: 'icShower', icon: Shower, title: 'Shower' },
  { name: 'icHotTub', icon: HotTub, title: 'HotTub' },
  { name: 'icToilet', icon: Toilet, title: 'Toilet' },
  { name: 'icToiletPaper', icon: ToiletPaper, title: 'Toilet paper' },
  { name: 'icToiletPaperOff', icon: ToiletPaperOff, title: 'Toilet paper off' },
  { name: 'icWashingMachine', icon: WashingMachine, title: 'Washing machine' },
  { name: 'icWashingMachine2', icon: WashingMachine2, title: 'Washing machine 2' },
  { name: 'icFridge', icon: Fridge, title: 'Fridge' },
  { name: 'icCouch', icon: Couch, title: 'Couch' },
  { name: 'icBed', icon: Bed, title: 'Bed' },
  { name: 'icArmchair', icon: Armchair, title: 'Armchair' },
  { name: 'icBarChair', icon: BarChair, title: 'Bar chair' },
  { name: 'icTv', icon: Tv, title: 'Tv' },
  { name: 'icDoorClose', icon: DoorClose, title: 'Room door' },
  { name: 'icDoorOpen', icon: DoorOpen, title: 'Room door' },
  { name: 'icSpeaker', icon: Speaker, title: 'Speaker' },
  { name: 'icVpn', icon: Vpn, title: 'Vpn' },
  { name: 'icWifi', icon: Wifi, title: 'Wi-fi' },
  { name: 'icWifiOff', icon: WifiOff, title: 'Wi-fi off' },
  { name: 'icWiFiRouter', icon: WiFiRouter, title: 'Wi-fi router' },
  { name: 'icWiFiRouter2', icon: WiFiRouter2, title: 'Wi-fi router 2' },
  { name: 'icBedsideTable', icon: BedsideTable, title: 'Bedside table' },
  { name: 'icBedsideTable2', icon: BedsideTable2, title: 'Bedside table 2' },
  { name: 'icCloset', icon: Closet, title: 'Closet' },
  { name: 'icChandelier', icon: Chandelier, title: 'Chandelier' },
  { name: 'icLamp', icon: Lamp, title: 'Lamp' },
  { name: 'icFlashlight', icon: Flashlight, title: 'Flashlight' },
  { name: 'icShirt', icon: Shirt, title: 'Shirt' },
  { name: 'icHanger', icon: Hanger, title: 'Hanger Protocol' },
  { name: 'icHanger2', icon: Hanger2, title: 'Hanger 2' },
  { name: 'icWeigher', icon: Weigher, title: 'Weigher' },
  // Tools
  { name: 'icCalculationAlt', icon: CalculationAlt, title: 'Calculation Alt' },
  { name: 'icCut', icon: Cut, title: 'Cut' },
  { name: 'icPaintBrush', icon: PaintBrush, title: 'Paint Brush' },
  { name: 'icPaintbrush2', icon: Paintbrush2, title: 'Paintbrush2' },
  { name: 'icPaintRoll', icon: PaintRoll, title: 'Paint roll' },
  { name: 'icPaintBucket', icon: PaintBucket, title: 'Paint bucket' },
  { name: 'icBroom', icon: Broom, title: 'Broom' },
  { name: 'icSpanner', icon: Spanner, title: 'Spanner' },
  { name: 'icHammer', icon: Hammer, title: 'Hammer' },
  { name: 'icScalesTipped', icon: ScalesTipped, title: 'Scales tipped' },
  { name: 'icCleaningRoom', icon: CleaningRoom, title: 'Cleaning room' },
  { name: 'icСog', icon: Сog, title: 'Сog' },
  { name: 'icTrashCan', icon: TrashCan, title: 'Trash Can' },
  { name: 'icChemistry', icon: Chemistry, title: 'Chemistry' },
  { name: 'icBinoculars', icon: Binoculars, title: 'Binoculars' },
  { name: 'icBullhorn', icon: Bullhorn, title: 'Bullhorn' },
  { name: 'icCalibrate', icon: Calibrate, title: 'Calibrate' },
  { name: 'icMicroscope', icon: Microscope, title: 'Microscope' },
  { name: 'icStethoscope', icon: Stethoscope, title: 'Stethoscope' },
  { name: 'icToolKit', icon: ToolKit, title: 'Tool Kit' },
  { name: 'icTools', icon: Tools, title: 'Tools' },
  { name: 'icToolsAlt', icon: ToolsAlt, title: 'Tools Alt' },
  { name: 'icClean', icon: Clean, title: 'Clean' },
  // Medical
  { name: 'icMedication', icon: Medication, title: 'Medication' },
  { name: 'icMedicationAlert', icon: MedicationAlert, title: 'Medication alert' },
  { name: 'icPills', icon: Pills, title: 'Pills' },
  { name: 'icPillsAdd', icon: PillsAdd, title: 'Pills add' },
  { name: 'icPillsSubtract', icon: PillsSubtract, title: 'Pills subtract' },
  { name: 'icWorshipChristian', icon: WorshipChristian, title: 'Worship christian' },
  // Files
  { name: 'icBox', icon: Box, title: 'Box' },
  { name: 'icEmail', icon: Email, title: 'Email' },
  { name: 'icFolder', icon: Folder, title: 'Folder' },
  { name: 'icDocumentBlank', icon: DocumentBlank, title: 'Document Blank' },
  { name: 'icDocumentMultiple01', icon: DocumentMultiple01, title: 'Document Multiple 01' },
  { name: 'icDocument', icon: Document, title: 'Document' },
  { name: 'icDocumentExport', icon: DocumentExport, title: 'Document export' },
  { name: 'icDocumentImport', icon: DocumentImport, title: 'Document import' },
  { name: 'icDocumentTasks', icon: DocumentTasks, title: 'Document tasks' },
  { name: 'icDocumentSubtract', icon: DocumentSubtract, title: 'Document subtract' },
  { name: 'icResultDraft', icon: ResultDraft, title: 'Result Draft' },
  { name: 'icReport', icon: Report, title: 'Report' },
  { name: 'icReportData', icon: ReportData, title: 'Report Data' },
  { name: 'icTask', icon: Task, title: 'Task' },
  { name: 'icTaskComplete', icon: TaskComplete, title: 'Task complete' },
  { name: 'icTaskSettings', icon: TaskSettings, title: 'Task settings' },
  { name: 'icTaskStar', icon: TaskStar, title: 'Task star' },
  { name: 'icTaskTools', icon: TaskTools, title: 'Task tools' },
  { name: 'icTaskView', icon: TaskView, title: 'Task view' },
  { name: 'icLicenseThirdParty', icon: LicenseThirdParty, title: 'License Third Party' },
  { name: 'icLicenseThirdPartyDraft', icon: LicenseThirdPartyDraft, title: 'License third party draft' },
  { name: 'icWhitePaper', icon: WhitePaper, title: 'White paper' },
  { name: 'icPolicy', icon: Policy, title: 'Policy' },
  { name: 'icCatalogPublish', icon: CatalogPublish, title: 'Catalog publish' },
  { name: 'icChangeCatalog', icon: ChangeCatalog, title: 'Change catalog' },
  { name: 'icDocumentAttachment', icon: DocumentAttachment, title: 'Document attachment' },
  { name: 'icEnvelopeClosed', icon: EnvelopeClosed, title: 'Envelope closed' },
  { name: 'icEnvelopeLetter', icon: EnvelopeLetter, title: 'Envelope letter' },
  { name: 'icEnvelopeOpen', icon: EnvelopeOpen, title: 'Envelope open' },
  { name: 'icTag', icon: Tag, title: 'Tag' },
  { name: 'icTagNone', icon: TagNone, title: 'Tag none' },
  { name: 'icTagExport', icon: TagExport, title: 'Tag export' },
  { name: 'icTagImport', icon: TagImport, title: 'Tag import' },
  { name: 'icTagEdit', icon: TagEdit, title: 'Tag edit' },
  { name: 'icTagGroup', icon: TagGroup, title: 'Tag group' },
  { name: 'icImage', icon: ImageIcon, title: 'Image' },
  { name: 'icImageIcon2', icon: ImageIcon2, title: 'Image 2' },
  { name: 'icImageCopy', icon: ImageCopy, title: 'Image copy' },
  { name: 'icImageReference', icon: ImageReference, title: 'Image reference' },
  { name: 'icImageSearch', icon: ImageSearch, title: 'Image search' },
  { name: 'icImageSearchAlt', icon: ImageSearchAlt, title: 'Image search alt' },
  { name: 'icAttachment', icon: Attachment, title: 'Attachment' },
  { name: 'icPen', icon: Pen, title: 'Pen' },
  { name: 'icPenFountain', icon: PenFountain, title: 'Pen Fountain' },
  { name: 'icStamp', icon: Stamp, title: 'Stamp' },
  { name: 'icRuler', icon: Ruler, title: 'Ruler' },
  { name: 'icRulerAlt', icon: RulerAlt, title: 'Ruler alt' },
  { name: 'icScalpel', icon: Scalpel, title: 'Scalpel' },
  { name: 'icFilter', icon: Filter, title: 'Filter' },
  { name: 'icPin', icon: Pin, title: 'Pin' },
  { name: 'icTranslate', icon: Translate, title: 'Translate' },
  { name: 'icBook', icon: Book, title: 'Book' },
  { name: 'icBookBookmark', icon: BookBookmark, title: 'BookBookmark' },
  // Weather
  { name: 'icSun', icon: Sun, title: 'Sun' },
  { name: 'icAsleep', icon: Asleep, title: 'Asleep' },
  { name: 'icCloud', icon: Cloud, title: 'Cloud' },
  { name: 'icRain', icon: Rain, title: 'Rain' },
  { name: 'icSnow', icon: Snow, title: 'Snow' },
  { name: 'icUmbrella', icon: Umbrella, title: 'Umbrella' },
  { name: 'icFavorite', icon: Favorite, title: 'Favorite' },
  { name: 'icFlash', icon: Flash, title: 'Flash' },
  { name: 'icStar', icon: Star, title: 'Star' },
  { name: 'icRainDrop', icon: RainDrop, title: 'Rain Drop' },
  { name: 'icSprout', icon: Sprout, title: 'Sprout' },
  { name: 'icTree', icon: Tree, title: 'Tree' },
  { name: 'icPalmTree', icon: PalmTree, title: 'Palm Tree' },
  { name: 'icFlower', icon: Flower, title: 'Flower' },
  { name: 'icPest', icon: Pest, title: 'Pest' },
  { name: 'icBee', icon: Bee, title: 'Bee' },
  // Other
  { name: 'icCalendar', icon: Calendar, title: 'Calendar' },
  { name: 'icTime', icon: Time, title: 'Time' },
  { name: 'icMicrophone', icon: Microphone, title: 'Microphone' },
  { name: 'icHeadset', icon: Headset, title: 'Headset' },
  { name: 'icNotification', icon: Notification, title: 'Notification' },
  { name: 'icPhone', icon: Phone, title: 'Phone' },
  { name: 'icWifiController', icon: WifiController, title: 'Wifi Controller' },
  { name: 'icHealthCross', icon: HealthCross, title: 'Health Cross' },
  { name: 'icChat', icon: Chat, title: 'Chat' },
  { name: 'icChatBubble', icon: ChatBubble, title: 'Chat bubble' },
  { name: 'icChat2', icon: Chat2, title: 'Chat 2' },
  { name: 'icChatBubbleText', icon: ChatBubbleText, title: 'Chat bubble text' },
  { name: 'icThumbDown', icon: ThumbDown, title: 'Thumb down' },
  { name: 'icThumbUp', icon: ThumbUp, title: 'Thumb up' },
  { name: 'icTaxi', icon: Taxi, title: 'Taxi' },
  { name: 'icHandHeart', icon: HandHeart, title: 'Hand heart' },
  { name: 'icHandMoney', icon: HandMoney, title: 'Hand money' },
  { name: 'icHandPills', icon: HandPills, title: 'Hand pills' },
  { name: 'icBookmark', icon: Bookmark, title: 'Bookmark' },
  { name: 'icFlag', icon: Flag, title: 'Flag' },
  { name: 'icLocked', icon: Locked, title: 'Locked' },
  { name: 'icUnlocked', icon: Unlocked, title: 'Unlocked' },
  { name: 'icIdea', icon: Idea, title: 'Idea' },
  { name: 'icBatteryHalf', icon: BatteryHalf, title: 'Battery Half' },
  { name: 'icPlug', icon: Plug, title: 'Plug' },
  { name: 'icPlugZap', icon: PlugZap, title: 'Plug zap' },
  { name: 'icPuzzle', icon: Puzzle, title: 'Puzzle' },
  { name: 'icConfetti', icon: Confetti, title: 'Confetti' },
  { name: 'icBurst', icon: Burst, title: 'Burst' },
  { name: 'icGuitars', icon: Guitars, title: 'Guitars' },
  { name: 'icDumbbells', icon: Dumbbells, title: 'Dumbbells' },
  { name: 'icCosmetic', icon: Cosmetic, title: 'Cosmetic' },
  { name: 'icGlasses', icon: Glasses, title: 'Glasses' },
  { name: 'icDevicePhonePortrait', icon: DevicePhonePortrait, title: 'Device phone portrait' },
  { name: 'icDeviceTabletPortrait', icon: DeviceTabletPortrait, title: 'Device tablet portrait' },
  { name: 'icBirthdayCake', icon: BirthdayCake, title: 'Birthday cake' },
];
