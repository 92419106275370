<template>
  <view-field class="font-weight-medium" :label="label">
    <component :is="currentIcon.icon" width="70" />
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

// Icons
import requestIcons from '@/assets/icons/requestIcons';

export default {
  name: 'SchemaViewFieldRequestIcon',

  components: {
    ViewField,
  },

  props: {
    value: { type: String, default: null },
    label: { type: String, required: true },
  },

  computed: {
    currentIcon() {
      return requestIcons.find(({ name }) => name === this.value) || {};
    },
  },
};
</script>
