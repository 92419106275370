<template>
  <div>
    <div v-if="value">
      <p class="text-body-1 mb-2 text--secondary">{{ label }}</p>
      <div class="d-flex align-center justify-space-between">
        <component :is="selectedIcon.icon" width="70" />
        <icon-button exact :width="36" class="ml-2" @click.stop="deleteIcon">
          <v-icon color="primary">mdi-delete</v-icon>
        </icon-button>
      </div>
    </div>
    <v-menu v-else offset-y :close-on-content-click="false" max-width="531" max-height="272">
      <template #activator="{ on, attrs }">
        <div class="d-flex pb-2 mt-3">
          <span v-bind="attrs" class="d-flex align-center primary--text text-uppercase" v-on="on">
            <v-icon color="primary" class="me-4">mdi-plus</v-icon>
            {{ $t('button.add_icon') }}
            <span v-if="isRequiredField" class="ms-1">*</span>
          </span>
        </div>
      </template>
      <template>
        <v-card>
          <div class="icon-select__search-field">
            <text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="multi-select__search mt-0 pt-0 mb-2"
              hide-details
              :placeholder="$t(placeholderSearch)"
            />
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="icon in filtredIcons" :key="icon.value" class="px-1 py-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon large v-bind="attrs" @click="selectIcon(icon)" v-on="on">
                    <component :is="icon.icon" width="24" height="24" />
                  </v-btn>
                </template>
                <span>{{ icon.title }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';
import { debounce } from '@/utils/delay';

import { fetchRequestsCategoriesIcons } from '@/services/select';
import TextField from '@/components/TextField.vue';
import IconButton from '@/components/IconButton.vue';

// Icons
import requestIcons from '@/assets/icons/requestIcons';

export default {
  name: 'SchemaFormFieldRequestsCategoryIcons',

  components: {
    TextField,
    IconButton,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: [Object, String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    isRequiredField: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    hiddenSearch: { type: Boolean, default: false },
    placeholderSearch: { type: String, default: 'label.search_icons' },
  },
  data() {
    return {
      search: '',
      filtredIcons: [],
    };
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    selectedIcon() {
      return requestIcons.find(({ name }) => name === this.value) || {};
    },
  },

  watch: {
    search() {
      this.debounceSearch();
    },
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.multiselect?.debouncedFetchItems();
      }
    },
    valueModel() {
      this.$emit('validate');
    },
  },

  mounted() {
    this.filtredIcons = [...requestIcons];
  },

  methods: {
    queryItems(payload) {
      if (payload.search === '') {
        return fetchRequestsCategoriesIcons({ ...payload }).then(({ results }) => results);
      }
      return fetchRequestsCategoriesIcons({ ...payload, ...this.payload }).then(({ results }) => results);
    },

    // eslint-disable-next-line func-names
    debounceSearch: debounce(function() {
      this.searchIcons();
    }, 300),

    searchIcons() {
      this.filtredIcons = requestIcons.filter(({ title }) => title.toLowerCase().includes(this.search.toLowerCase()));
    },

    selectIcon(icon) {
      // setPropertyValue({ propPath, value: $event })
      this.valueModel = icon.name;
    },

    deleteIcon() {
      // setPropertyValue({ propPath, value: $event })
      this.valueModel = null;
    },
  },

  compareById,
};
</script>

<style lang="scss">
.icon-select {
  &__search-field {
    position: sticky;
    background: $--white-color-0;
    top: 0;
    z-index: 1;
  }
}
</style>
