<template>
  <div class="calendar">
    <calendar-modal ref="usageInfo" />

    <calendar-list
      ref="list"
      :items="visibleUnits"
      :viewport-height="viewportHeight"
      :cell-height="cellHeight"
      :offset-y="offsetY"
      :scroll-top="scrollTop"
      :total-height="totalHeight"
    />

    <div class="calendar__chart">
      <calendar-chart-calendar
        ref="calendar"
        :items="visibleDates"
        :months-items="visibleMonths"
        :cell-height="cellHeight"
        :cell-width="cellWidth"
        :total-width="totalWidth"
        :viewport-height="viewportHeight"
        :viewport-width="viewportWidth"
        :scroll-left="scrollLeft"
        :offset-x="offsetX"
        :first-width="firstWidth"
        :last-width="lastWidth"
        :is-loading="isLoading"
      />

      <calendar-chart-timeline
        ref="timeline"
        :items="visibleUnits"
        :date-items="visibleDates"
        :total-height="totalHeight"
        :total-width="totalWidth"
        :viewport-height="viewportHeight"
        :viewport-width="viewportWidth"
        :scroll-top="scrollTop"
        :scroll-left="scrollLeft"
        :offset-y="offsetY"
        :offset-x="offsetX"
        :first-width="firstWidth"
        :last-width="lastWidth"
        :today-position-left="todayPositionLeft"
        :ghost-booking="isDrag ? draggableBookingClone : null"
        :disable-items="disableItems"
        @open-usage-info="openUsageInfo"
        @open-modal-for-create="$emit('open-modal-for-create', $event)"
        @start-drag="startDrag"
        @start-resize="startResize"
      />
    </div>
  </div>
</template>

<script>
import {
  format,
  sub,
  addMonths,
  differenceInDays,
  differenceInCalendarMonths,
  addDays,
  subDays,
  getDate,
  startOfMonth,
  endOfMonth,
  getISODay,
  getDaysInMonth,
  isToday,
  isAfter,
  isBefore,
  areIntervalsOverlapping,
  startOfDay,
  formatISO,
  endOfDay,
  isSameDay,
  differenceInCalendarDays,
} from 'date-fns';
// Http
import client from '@/http/client';

// Services
import calendarService from '@/services/calendar';
import notificationService from '@/services/notification';

// Utils
import { clone } from '@/utils/clone';
import { debounce } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import { translateShortDate, translateShortWeek } from '@/utils/dateFormatting';

// Components
import CalendarModal from '@/components/Calendar/CalendarModal.vue';
import CalendarList from './CalendarList.vue';
import CalendarChartCalendar from './CalendarChartCalendar.vue';
import CalendarChartTimeline from './CalendarChartTimeline.vue';

export default {
  name: 'Graph',

  components: { CalendarList, CalendarModal, CalendarChartCalendar, CalendarChartTimeline },

  props: {
    currentDate: {
      type: Date,
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  provide() {
    return {
      addNewBooking: this.addNewBooking,
      updateBooking: this.updateBooking,
      archiveBooking: this.archiveBooking,
      changeBooking: this.changeBooking,
      normalizeBooking: this.normalizeBooking,
    };
  },

  data() {
    return {
      isLoading: true,
      totalItemsCount: 0,
      offset: 0,
      units: [],
      dates: [],

      monthsCount: 49,
      nodePadding: 4,

      cellHeight: 56,
      cellWidth: 32.65,

      viewportHeight: 0,
      viewportWidth: 0,

      scrollTop: 0,
      scrollLeft: 0,

      needUpdateList: true,

      // Drag / resize booking
      isDrag: false,
      isResize: false,
      resizeSide: null,
      draggableBooking: null,
      draggableBookingClone: null,
      draggableBookingInitRentalPeriod: null,
      nearbyBookings: null,
      intervalId: null,
      timeoutId: null,
      boost: 0,
      disableItems: false,
      startDragDate: null,
    };
  },

  computed: {
    startIndexY() {
      const startNode = Math.floor(this.scrollTop / this.cellHeight) - this.nodePadding;
      return Math.max(0, startNode);
    },
    startIndexX() {
      const startNode = Math.floor(this.scrollLeft / this.cellWidth);
      return Math.max(0, startNode);
    },
    unitCount() {
      return this.units.length;
    },
    dateCount() {
      return this.dates.length;
    },

    visibleNodeCountY() {
      let count = this.visibleCellCount + 2 * this.nodePadding;
      count = Math.min(this.unitCount - this.startIndexY, count);
      return count;
    },
    visibleNodeCountX() {
      return Math.ceil((this.viewportWidth - this.firstWidth) / this.cellWidth) + 1;
    },
    visibleCellCount() {
      return Math.ceil(this.viewportHeight / this.cellHeight);
    },
    visibleDates() {
      return this.dates.slice(this.startIndexX, this.startIndexX + this.visibleNodeCountX);
    },
    visibleMonths() {
      const monthList = [];
      if (!this.visibleDates.length) {
        return monthList;
      }
      const firstDate = this.visibleDates[0].value;
      const lastDate = this.visibleDates[this.visibleDates.length - 1].value;
      const monthsCount = differenceInCalendarMonths(lastDate, firstDate) + 1;

      for (let i = 0; i < monthsCount; i += 1) {
        const date = i ? startOfMonth(addMonths(firstDate, i)) : addMonths(firstDate, i);
        const id = `${date.getFullYear()}-${date.getMonth()}`;
        const label = translateShortDate(format(date, 'L-yyyy'));

        let daysToEnd = 0;
        let width = 0;

        if (i !== monthsCount - 1) {
          daysToEnd = differenceInDays(endOfMonth(date), date) + 1;
        } else {
          daysToEnd = differenceInDays(lastDate, date) + 1;
        }

        if (i === 0) {
          width = daysToEnd * this.cellWidth - (this.cellWidth - this.firstWidth);
        } else if (i === monthsCount - 1) {
          width = daysToEnd * this.cellWidth - (this.cellWidth - this.lastWidth);
        } else {
          width = daysToEnd * this.cellWidth;
        }

        const month = { id, label, width, value: date };
        monthList.push(month);
      }

      return monthList;
    },
    visibleUnits() {
      const visibleUnits = this.units.slice(this.startIndexY, this.startIndexY + this.visibleNodeCountY);
      while (visibleUnits.length < this.visibleCellCount) {
        visibleUnits.push({
          id: `add-${visibleUnits.length}`,
        });
      }

      if (this.visibleDates.length) {
        return visibleUnits.map(item => {
          return item.bookings
            ? {
                ...item,
                bookings: item.bookings.map(booking => this.normalizeBooking(booking, item.id)),
                archivedBookings: item.archivedBookings.map(booking => this.normalizeBooking(booking, item.id)),
              }
            : item;
        });
      }

      return visibleUnits;
    },
    totalHeight() {
      const height = this.units.length * this.cellHeight;
      return height < this.viewportHeight ? this.viewportHeight : height;
    },
    totalWidth() {
      return this.dateCount * this.cellWidth;
    },
    offsetY() {
      return this.startIndexY * this.cellHeight;
    },
    offsetX() {
      return this.startIndexX * this.cellWidth;
    },
    firstWidth() {
      return this.cellWidth - (this.scrollLeft - this.startIndexX * this.cellWidth);
    },
    lastWidth() {
      return (
        this.viewportWidth -
        this.cellWidth * (this.visibleNodeCountX - 1) +
        this.scrollLeft -
        this.startIndexX * this.cellWidth -
        1
      );
    },
    todayPositionLeft() {
      const today = this.visibleDates.find(date => date.isToday);
      const diff = differenceInDays(today?.value, this.visibleDates[0]?.value);
      return diff || diff === 0 ? diff * this.cellWidth + this.firstWidth : null;
    },
  },

  watch: {
    filters: {
      handler() {
        this.debounceGetList();
      },
      deep: true,
    },
    currentDate() {
      if (this.needUpdateList) {
        this.debounceGetList();
        this.setDates();
      } else {
        this.needUpdateList = true;
      }

      // if (!isSameMonth(newDate, this.visibleMonths[0].value)) {
      //   this.setDates();
      // }
    },
    visibleMonths(newValue, oldValue) {
      if (newValue[0]?.label !== oldValue[0]?.label) {
        this.needUpdateList = false;
        this.$emit('change-month', newValue[0].value);
      }
    },
  },

  created() {
    this.debounceGetList = debounce(this.getList, 300);
  },

  mounted() {
    this.updateList();
    this.$nextTick(() => {
      this.getViewportSizes();
      this.setDates();
    });

    window.onresize = () => this.getViewportSizes();
    window.addEventListener('mouseup', e => (this.isResize || this.isDrag) && this.stopDrag(e));
    window.addEventListener('mousemove', e => {
      if (this.isResize) {
        this.isDrag = false;
        this.resizeBooking(e);
        return;
      }

      if (this.isDrag) this.moveBooking(e);
    });

    this.$refs.calendar.$refs.scroller.addEventListener('scroll', e => this.handleScrollLeft(e.target));
    this.$refs.list.$refs.scroller.addEventListener('scroll', e => this.handleScrollTop(e.target));
    this.$refs.timeline.$refs.scroller.addEventListener('scroll', e => {
      this.handleScrollLeft(e.target);
      this.handleScrollTop(e.target);
    });
  },

  methods: {
    async getList(offset = 0) {
      if (this.$options.cancelSource) {
        this.$options.cancelSource.cancel();
        await flushPromises();
      }

      this.isLoading = true;

      this.totalItemsCount = 0;
      this.offset = 0;
      this.units = [];

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelSource = cancelSource;
        const config = { cancelToken: cancelSource.token };

        const { count, results } = await this.fetchData(offset, config);

        this.totalItemsCount = count;
        this.offset = results.length;
        this.addUnitsToList(results);
      } finally {
        this.isLoading = false;
        this.$options.cancelSource = null;
      }
    },

    fetchData(offset, config) {
      return calendarService.getCalendarList(
        this.normalizedFilters(),
        {
          limit: this.limit,
          offset,
          rentalPeriodBefore: this.filters.rentalPeriodBefore,
          rentalPeriodAfter: this.filters.rentalPeriodAfter,
        },
        config
      );
    },

    async updateList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        const { results } = await this.fetchData(this.offset);
        this.offset += results.length;
        this.addUnitsToList(results);
      } finally {
        this.isLoading = false;
      }
    },

    addUnitsToList(newUnits) {
      let startIndexY = this.units.length ? this.units.length : 0;

      newUnits.forEach(unit => {
        this.units.push({ ...unit, type: 'unit', index: startIndexY });
        startIndexY += 1;
        unit.rooms.forEach(room => {
          this.units.push({ ...room, type: 'room', index: startIndexY });
          startIndexY += 1;
        });
      });
    },

    addNewBooking(booking) {
      for (let i = 0; i < this.units.length; i += 1) {
        if (this.units[i].id === (booking.room?.id || booking.unit.id)) {
          this.units[i].bookings.push(booking);
          break;
        }
      }
    },

    setDates() {
      this.dates = [];
      this.monthsList = [];

      const startMonth = sub(startOfMonth(this.currentDate), { years: 2 });
      let datesCount = 0;

      for (let i = 0; i < this.monthsCount; i += 1) {
        const month = addMonths(startMonth, i);

        this.monthsList.push({
          label: translateShortDate(format(month, 'L-yyyy')),
          width: getDaysInMonth(month) * this.cellHeight,
          value: month,
        });

        datesCount += getDaysInMonth(month);
      }

      for (let i = 0; i < datesCount; i += 1) {
        const nextDate = addDays(startMonth, i);

        this.dates.push({
          id: `${format(new Date(nextDate), 'yyyy-MM-dd')}`,
          day: getDate(nextDate),
          isToday: isToday(nextDate),
          week: translateShortWeek(getISODay(nextDate)),
          value: nextDate,
        });
      }

      this.scrollLeft = differenceInDays(startOfMonth(this.currentDate), startMonth) * this.cellWidth + 2;
    },

    updateBooking(index, bookingData) {
      const unit = this.units[index];

      if (unit.id === (bookingData.room?.id || bookingData.unit.id)) {
        const booking = unit.bookings.filter(item => {
          return item.usageId === bookingData.usageId;
        })[0];

        booking.rentalPeriod = bookingData.rentalPeriod;
      } else {
        let newIndex;
        unit.bookings = unit.bookings.filter(item => item.usageId !== bookingData.usageId);
        for (let i = 0; i < this.units.length; i += 1) {
          if (this.units[i].id === (bookingData.room?.id || bookingData.unit.id)) {
            this.units[i].bookings.push({
              clientId: bookingData.clientId,
              firstName: bookingData.firstName,
              lastName: bookingData.lastName,
              rentalPeriod: bookingData.rentalPeriod,
              usageId: bookingData.usageId,
            });

            newIndex = this.units[i].index;
            break;
          }
        }
        return newIndex;
      }
      return null;
    },

    archiveBooking(index, booking) {
      const unit = this.units[index];
      const { rentalPeriodStartDate, rentalPeriodEndDate } = booking;

      if (isAfter(new Date(rentalPeriodStartDate), new Date())) {
        unit.bookings = unit.bookings.filter(item => item.usageId !== booking.usageId);
        return;
      }

      if (isAfter(new Date(rentalPeriodEndDate), new Date())) {
        const archiveBooking = {
          clientId: booking.clientId,
          firstName: booking.firstName,
          lastName: booking.lastName,
          rentalPeriod: {
            lower: rentalPeriodStartDate,
            upper: format(new Date(), 'yyyy-L-dd'),
          },
          usageId: booking.usageId,
        };

        unit.bookings = unit.bookings.filter(item => item.usageId !== booking.usageId);
        unit.archivedBookings.push(archiveBooking);
      }
    },

    getViewportSizes() {
      const viewport = this.$refs.timeline.$el;

      this.viewportHeight = viewport.clientHeight;
      this.viewportWidth = viewport.clientWidth;
    },

    handleScrollTop(block) {
      this.scrollTop = block.scrollTop;

      if (
        this.totalHeight <= this.scrollTop + this.viewportHeight + this.cellHeight &&
        this.unitCount < this.totalItemsCount
      ) {
        this.updateList();
      }
    },

    handleScrollLeft(block) {
      this.scrollLeft = block.scrollLeft;
    },

    normalizeBooking(booking, unitId) {
      const startVisibleDate = this.visibleDates[0].value;
      const endVisibleDate = this.visibleDates[this.visibleDates.length - 1].value;
      let width;

      const startDate = startOfDay(new Date(booking.rentalPeriod.lower));
      const endDate = startOfDay(new Date(booking.rentalPeriod.upper));

      // Аренда в зоне видимости
      const intervalsIsOverlapping = areIntervalsOverlapping(
        { start: startVisibleDate, end: addDays(endVisibleDate, 1) },
        { start: startDate, end: endDate }
      );

      const isVisibleStartDate = isAfter(startDate, subDays(startVisibleDate, 1)); // начало аренды после видимой начальной даты календаря
      const isVisibleEndDate = isBefore(endDate, addDays(endVisibleDate, 1)); // окончание аренды до видимой последней даты календаря

      if (intervalsIsOverlapping) {
        const margin = 2;
        const cellOffset = this.cellWidth / 2 + margin;

        const difStartDays = differenceInDays(startDate, startVisibleDate);
        const difStartToEndVisibleDays = differenceInDays(endVisibleDate, startDate);
        const difEndToStartVisibleDays = differenceInDays(endDate, startVisibleDate);

        const left = isVisibleStartDate
          ? `${difStartDays * this.cellWidth + cellOffset - (this.cellWidth - this.firstWidth)}px`
          : '0px';

        // Если начало и конец в зоне видимости
        if (isVisibleStartDate && isVisibleEndDate) {
          width = differenceInDays(endDate, startDate) * this.cellWidth - margin * 2;
        }

        // Если только начало в зоне видимости
        if (isVisibleStartDate && !isVisibleEndDate) {
          width = (difStartToEndVisibleDays - 1) * this.cellWidth + cellOffset + this.lastWidth - margin;
        }

        // Если только конец в зоне видимости
        if (!isVisibleStartDate && isVisibleEndDate) {
          width =
            (difEndToStartVisibleDays + 1) * this.cellWidth -
            (this.cellWidth - this.firstWidth) -
            (this.cellWidth / 2 + margin);
        }

        // Если начало и конец за пределами календаря
        if (!isVisibleStartDate && !isVisibleEndDate) {
          width = this.viewportWidth;
        }

        const borderRadiusLeft = isVisibleStartDate ? '8px' : '0px';
        const borderRadiusRight = isVisibleEndDate ? '8px' : '0px';

        return {
          ...booking,
          unitId: booking.unitId || unitId,
          isVisibleStartDate,
          isVisibleEndDate,
          style: {
            left,
            width: width < 0 ? 0 : `${width}px`,
            'border-radius': `${borderRadiusLeft} ${borderRadiusRight} ${borderRadiusRight} ${borderRadiusLeft}`,
          },
        };
      }

      return {
        ...booking,
        style: {
          display: 'none',
        },
      };
    },

    normalizedFilters() {
      return {
        projects: checkEmptyParams(this.filters?.projects)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.projects),
        buildings: checkEmptyParams(this.filters?.buildings)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.buildings),
        units: checkEmptyParams(this.filters?.units)
          ? undefined
          : extractParamsFromMultiSelectObject(this.filters?.units),
      };
    },

    openUsageInfo([usageId, index]) {
      this.$refs.usageInfo.open(usageId, index);
    },

    changeBooking(usageId, unitIndex, rentalPeriod) {
      const { bookings } = this.units[unitIndex];
      const booking = bookings.find(b => b.usageId === usageId);
      booking.rentalPeriod = rentalPeriod;
    },

    startDrag({ evt, booking }) {
      this.isDrag = true;
      this.draggableBooking = booking;
      this.draggableBookingClone = clone(booking);

      const shiftX = evt.clientX - evt.target.getBoundingClientRect().left;
      this.daysCoundFromShiftX = Math.round(shiftX / this.cellWidth);

      const [unit] = this.units.filter(item => item.id === booking.unitId);
      this.nearbyBookings = [
        ...unit.bookings.filter(book => book.usageId !== booking.usageId),
        ...unit.archivedBookings,
      ];

      const { left } = this.$refs.timeline.$el.getBoundingClientRect();
      const mouseStartPosition = evt.clientX;
      const mousePos = mouseStartPosition - left;
      const daysCountFromStartToMouse = Math.floor((mousePos - this.firstWidth) / this.cellWidth + 1);
      this.startDragDate = addDays(this.visibleDates[0]?.value, daysCountFromStartToMouse);
    },

    startResize({ side, booking }) {
      this.isResize = true;
      this.draggableBooking = booking;
      this.draggableBookingClone = clone(booking);
      this.resizeSide = side;
    },

    async stopDrag() {
      this.isDrag = false;
      this.isResize = false;
      clearInterval(this.intervalId);
      clearTimeout(this.timeoutId);
      this.intervalId = null;
      this.timeoutId = null;
      this.boost = 0;

      document.body.style.cssText = `
        -webkit-user-select: null;
        -moz-user-select: null;
        -ms-user-select: null;
        cursor: null;
      `;

      if (
        !isSameDay(
          new Date(this.draggableBooking.rentalPeriod.lower),
          new Date(this.draggableBookingClone.rentalPeriod.lower)
        ) ||
        !isSameDay(
          new Date(this.draggableBooking.rentalPeriod.upper),
          new Date(this.draggableBookingClone.rentalPeriod.upper)
        ) ||
        this.draggableBooking.unitId !== this.draggableBookingClone.unitId
      ) {
        const bodyRequest = {
          unit: this.draggableBooking.unitId,
          rentalPeriod: {
            lower: format(new Date(this.draggableBooking.rentalPeriod.lower), 'yyyy-L-d'),
            upper: format(new Date(this.draggableBooking.rentalPeriod.upper), 'yyyy-L-d'),
          },
        };

        await calendarService
          .updateBooking(bodyRequest, this.draggableBooking.usageId)
          .then(() => {
            notificationService.success(this.$t('calendar.booking_is_changed'), 2000);
          })
          .catch(err => {
            if (err?.response?.data?.non_field_errors?.length) {
              this.$options.notificationItem = notificationService.error(err.response.data.non_field_errors[0]);
            }

            const [unit] = this.units.filter(item => item.id === this.draggableBooking.unitId);
            const [oldUnit] = this.units.filter(item => item.id === this.draggableBookingClone.unitId);

            if (unit.id !== oldUnit.id) {
              const booking = {
                ...this.draggableBooking,
                unitId: this.draggableBookingClone.unitId,
                rentalPeriod: this.draggableBookingClone.rentalPeriod,
              };

              unit.bookings = unit.bookings.filter(item => item.usageId !== this.draggableBooking.usageId);
              oldUnit.bookings.push(this.normalizeBooking(booking));
            } else {
              const [booking] = unit.bookings.filter(book => book.usageId === this.draggableBooking.usageId);
              booking.rentalPeriod = { ...this.draggableBookingClone.rentalPeriod };
            }
          });
      }

      this.disableItems = false;
      this.draggableBooking = null;
      this.draggableBookingClone = null;
      this.nearbyBookings = null;
    },

    moveBooking(evt) {
      const { left, top, width, height } = this.$refs.timeline.$el.getBoundingClientRect();
      const mouseIsOuterTop = evt.clientY < top;
      const mouseIsOuterBottom = evt.clientY > top + height;
      const mouseIsOuterRight = evt.clientX > left + width;
      const mouseIsOuterLeft = evt.clientX < left;

      this.disableItems = true;
      document.body.style.cssText = `
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: grabbing;
      `;

      let { date } = evt.target.dataset;
      const { unitId } = evt.target.dataset;

      if (mouseIsOuterRight) {
        this.scrollCalendar('right', evt);
      } else if (mouseIsOuterLeft) {
        this.scrollCalendar('left', evt);
      } else if (mouseIsOuterTop) {
        this.scrollCalendar('top', evt);
      } else if (mouseIsOuterBottom) {
        this.scrollCalendar('bottom', evt);
      } else {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        this.intervalId = null;
        this.timeoutId = null;
        this.boost = 0;
      }

      if (mouseIsOuterTop || mouseIsOuterBottom) {
        date = this.visibleDates[Math.floor((evt.clientX - left) / this.cellWidth)].id;
      }

      if (mouseIsOuterRight) {
        date = this.visibleDates[this.visibleDates.length - 1].value;
      }

      if (mouseIsOuterLeft) {
        date = this.visibleDates[0].value;
      }

      const diff = differenceInCalendarDays(new Date(date), this.startDragDate);

      const { rentalPeriod } = this.draggableBooking;

      if (Number(unitId) && this.draggableBooking.unitId !== Number(unitId) && !evt.target.closest('.--disable-row')) {
        const [unit] = this.units.filter(item => item.id === this.draggableBooking.unitId);
        const [newUnit] = this.units.filter(item => item.id === Number(unitId));
        const nearbyBookings = [
          ...newUnit.bookings.filter(book => book.usageId !== this.draggableBooking.usageId),
          ...newUnit.archivedBookings,
        ];

        let newRentalPeriod = {
          lower: new Date(rentalPeriod.lower),
          upper: new Date(rentalPeriod.upper),
        };

        if (diff > 0) {
          newRentalPeriod = {
            lower: addDays(new Date(rentalPeriod.lower), diff),
            upper: addDays(new Date(rentalPeriod.upper), diff),
          };
        }

        if (diff < 0) {
          newRentalPeriod = {
            lower: subDays(new Date(rentalPeriod.lower), Math.abs(diff)),
            upper: subDays(new Date(rentalPeriod.upper), Math.abs(diff)),
          };
        }

        if (!this.checkIntersections(newRentalPeriod, nearbyBookings)) {
          const booking = {
            ...this.draggableBooking,
            unitId: newUnit.id,
            rentalPeriod: newRentalPeriod,
          };

          unit.bookings = unit.bookings.filter(item => item.usageId !== this.draggableBooking.usageId);
          newUnit.bookings.push(this.normalizeBooking(booking));
          this.draggableBooking = newUnit.bookings[newUnit.bookings.length - 1];

          this.nearbyBookings = [
            ...newUnit.bookings.filter(book => book.usageId !== this.draggableBooking.usageId),
            ...newUnit.archivedBookings,
          ];
        }
      }

      if (diff > 0) {
        const newRentalPeriod = {
          lower: addDays(new Date(rentalPeriod.lower), diff),
          upper: addDays(new Date(rentalPeriod.upper), diff),
        };
        if (!this.checkIntersections(newRentalPeriod)) {
          rentalPeriod.lower = newRentalPeriod.lower;
          rentalPeriod.upper = newRentalPeriod.upper;
          this.startDragDate = new Date(date);
        }
      }

      if (diff < 0) {
        const newRentalPeriod = {
          lower: subDays(new Date(rentalPeriod.lower), Math.abs(diff)),
          upper: subDays(new Date(rentalPeriod.upper), Math.abs(diff)),
        };
        if (!this.checkIntersections(newRentalPeriod)) {
          rentalPeriod.lower = newRentalPeriod.lower;
          rentalPeriod.upper = newRentalPeriod.upper;
          this.startDragDate = new Date(date);
        }
      }
    },

    resizeBooking(evt) {
      const { left, width } = this.$refs.timeline.$el.getBoundingClientRect();
      let { date } = evt.target.dataset;
      const mouseIsOuterRight = evt.clientX > left + width;
      const mouseIsOuterLeft = evt.clientX < left;
      this.disableItems = true;

      document.body.style.cssText = `
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        cursor: col-resize;
      `;

      if (mouseIsOuterRight) {
        this.scrollCalendar('right', evt);
      } else if (mouseIsOuterLeft) {
        this.scrollCalendar('left', evt);
      } else {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        this.intervalId = null;
        this.timeoutId = null;
        this.boost = 0;
      }

      if (mouseIsOuterRight) {
        date = this.visibleDates[this.visibleDates.length - 1].value;
      }

      if (mouseIsOuterLeft) {
        date = this.visibleDates[0].value;
      }

      if (!date) return;

      const { rentalPeriod } = this.draggableBooking;

      const newRentalPeriod = {
        lower: rentalPeriod.lower,
        upper: rentalPeriod.upper,
      };

      if (this.resizeSide === 'left') {
        const lower = formatISO(new Date(endOfDay(new Date(date))));
        newRentalPeriod.lower = lower || newRentalPeriod.lower;
      }

      if (this.resizeSide === 'right') {
        const upper = formatISO(new Date(startOfDay(new Date(date))));
        newRentalPeriod.upper = upper || newRentalPeriod.upper;
      }

      if (isBefore(new Date(newRentalPeriod.upper), new Date(newRentalPeriod.lower))) {
        return;
      }

      if (!this.checkIntersections(newRentalPeriod)) {
        rentalPeriod.lower = newRentalPeriod.lower;
        rentalPeriod.upper = newRentalPeriod.upper;
      }
    },

    checkIntersections(period, bookings) {
      const nearbyBookings = bookings || this.nearbyBookings;

      for (let i = 0; i < nearbyBookings?.length; i += 1) {
        const rentalPeriod = { ...nearbyBookings[i].rentalPeriod };
        const isOverlapping = areIntervalsOverlapping(
          { start: new Date(period.lower), end: new Date(period.upper) },
          { start: new Date(rentalPeriod.lower), end: new Date(rentalPeriod.upper) }
        );

        if (isOverlapping) {
          return true;
        }
      }
      return false;
    },

    scrollCalendar(direction, evt) {
      const { width, height } = this.$refs.timeline.$el.getBoundingClientRect();

      if (!this.intervalId && !this.timeoutId) {
        this.intervalId = setInterval(() => {
          if (direction === 'right' && this.scrollLeft + width < this.totalWidth) {
            this.scrollLeft += 5 + this.boost;
          } else if (direction === 'left' && this.scrollLeft > 0) {
            this.scrollLeft -= 5 + this.boost;
          } else if (direction === 'top' && this.scrollTop > 0) {
            this.scrollTop -= 5 + this.boost;
          } else if (direction === 'bottom' && this.scrollTop + height < this.totalHeight) {
            this.scrollTop += 5 + this.boost;
          }
          if (this.isResize) {
            this.resizeBooking(evt);
          }
          if (this.isDrag) {
            this.moveBooking(evt);
          }
        }, 5);

        this.timeoutId = setTimeout(() => {
          this.boost = 10;
        }, 500);
      }
    },
  },

  cancelSource: null,
  notificationItem: null,
};
</script>

<style lang="scss">
.calendar {
  min-height: 640px;
  height: calc(100vh - 340px);
  display: flex;
  overflow: hidden;
  font-size: 14px;
  background: #ffffff;
  border-bottom: 1px solid #e6ebff;

  * {
    box-sizing: border-box;
  }

  &__chart {
    flex: 1 1 100%;
    overflow: hidden;
    position: relative;
  }

  &__chart-scrollbar {
    overflow: hidden;
    position: absolute;

    &.--vertical {
      top: 116px;
      bottom: 15px;
      right: 0;
      width: 15px;
      height: calc(100% - 116px - 15px);

      .calendar__chart-scrollbar-inner {
        width: 100%;
        height: 100px;
      }
    }

    &.--horizontal {
      bottom: 0;
      right: 15px;
      width: calc(100% - 15px);
      height: 15px;

      .calendar__chart-scrollbar-inner {
        width: 100px;
        height: 100%;
      }
    }
  }

  &__chart-scrollbar-inner {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    position: absolute;
    touch-action: none;
    cursor: grab;
  }
}
</style>
