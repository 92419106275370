<template>
  <div class="calendar__chart-timeline-grid-bookings">
    <div
      v-for="(item, index) in items"
      :key="item.id"
      class="calendar__chart-timeline-grid-bookings-item"
      :class="{ disable: disableItems }"
      :style="{ height: index ? cellHeight + 'px' : cellHeight + 1 + 'px' }"
    >
      <booking-item-ghost
        v-if="normalizeGhostBooking && normalizeGhostBooking.unitId === item.id"
        :booking="normalizeGhostBooking"
      />
      <booking-item-ghost
        v-if="normalizeGhostNewBooking && normalizeGhostNewBooking.unitId === item.id"
        :booking="normalizeGhostNewBooking"
      />
      <booking-item
        v-for="booking in item.bookings"
        :key="booking.usageId"
        :booking="booking"
        :unit-bookings="[...item.archivedBookings, ...item.bookings]"
        :unit-index="item.index"
        :unit-id="item.id"
        :scroll-left="scrollLeft"
        :cell-width="cellWidth"
        :first-width="firstWidth"
        :first-date="firstDate"
        :last-date="lastDate"
        @open-usage-info="$emit('open-usage-info', [booking.usageId, item.index])"
        @start-drag="$emit('start-drag', $event)"
        @start-resize="$emit('start-resize', $event)"
      />
      <booking-item
        v-for="booking in item.archivedBookings"
        :key="booking.usageId"
        :booking="booking"
        :unit-index="item.index"
        :unit-id="item.id"
        :unit-bookings="[...item.archivedBookings, ...item.bookings]"
        is-archived
        @open-usage-info="$emit('open-usage-info', [booking.usageId, item.index])"
      />
    </div>
  </div>
</template>

<script>
import BookingItem from './BookingItem.vue';
import BookingItemGhost from './BookingItemGhost.vue';

export default {
  name: 'CalendarChartTimelineGrid',

  inject: ['normalizeBooking'],

  components: {
    BookingItem,
    BookingItemGhost,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    cellHeight: {
      type: Number,
      default: 56,
    },
    cellWidth: {
      type: Number,
      default: 32.65,
    },
    scrollLeft: {
      type: Number,
      default: 0,
    },
    firstDate: {
      type: Date,
      default: null,
    },
    lastDate: {
      type: Date,
      default: null,
    },
    firstWidth: {
      type: Number,
      default: 32.65,
    },
    ghostNewBook: {
      type: Object,
      default: null,
    },
    ghostBooking: {
      type: Object,
      default: null,
    },
    disableItems: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    normalizeGhostBooking() {
      return this.ghostBooking ? this.normalizeBooking(this.ghostBooking) : null;
    },
    normalizeGhostNewBooking() {
      return this.ghostNewBook ? this.normalizeBooking(this.ghostNewBook) : null;
    },
  },

  methods: {
    getName(firstName, lastName, width) {
      const fixedWidth = Number(parseFloat(width).toFixed());
      const lettersWidth = 9;

      const firstNameLength = firstName.length * lettersWidth;
      const lastNameLength = lastName.length * lettersWidth;

      if (firstNameLength + lastNameLength < fixedWidth) {
        return `${firstName} ${lastName}`;
      }

      if (firstNameLength + lettersWidth < fixedWidth) {
        return `${firstName} ${lastName && `${lastName[0]}.`}`;
      }

      if (firstNameLength + lastNameLength > fixedWidth) {
        return `${firstName[0]}. ${lastName && `${lastName[0]}.`}`;
      }

      return `${firstName} ${lastName}`;
    },
  },
};
</script>

<style lang="scss">
.calendar {
  &__chart-timeline-grid-bookings,
  &__chart-timeline-grid-bookings-item {
    position: relative;
    pointer-events: none;

    &.disable {
      .booking-item {
        pointer-events: none;
      }
    }
  }
}
</style>
