import { v4 as uuid } from 'uuid';
import { clone } from '@/utils/clone';
import { FORM } from '@/constants/builder';
import { NONE } from '@/constants/value';
import { format } from 'date-fns';
import * as schemaItemTypes from './schemaItemTypes';
import { UNIQUE_KEY } from './config';

const getNull = () => null;
const getString = () => '';
const getNumber = () => 0;
const getBoolean = () => false;
const getList = () => [];
const getMultiSelectObject = () => ({
  assignAllCurrentAndFuture: false,
  all: false,
  include: [],
  exclude: [],
});

const getRequestRolesObject = () => {
  return {
    rolesPending: getMultiSelectObject(),
    rolesInProgress: getMultiSelectObject(),
    rolesCompleted: getMultiSelectObject(),
  };
};

export const getCredentialsObject = () => ({
  invoices: [],
  contractors: [],
  clients: [],
  announcements: [],
  employees: [],
  chat: [],
  projects: [],
  dashboard: [],
  contracts: [],
  issues: [],
  services: [],
  requests: [],
});

const getCardSection = () => ({
  info: {
    show: false,
    colorIcon: '',
    text: '',
    icon: '',
  },
});

const getCurrentMonth = () => {
  return format(new Date(), 'yyyy-L');
};

const PROPERTY_FACTORY_MAP = {
  [schemaItemTypes.STRING]: getString,
  [schemaItemTypes.REQUEST_ICON]: getString,
  [schemaItemTypes.NUMBER]: getNumber,
  [schemaItemTypes.SQUARE]: getNumber,
  [schemaItemTypes.BOOLEAN]: getBoolean,
  [schemaItemTypes.PROJECT]: getNull,
  [schemaItemTypes.BUILDING]: getNull,
  [schemaItemTypes.REQUEST]: getNull,
  [schemaItemTypes.ENUM]: getNull,
  [schemaItemTypes.MANY_ENUM]: getList,
  [schemaItemTypes.LIST]: getList,
  [schemaItemTypes.REQUEST_FORM_LIST]: getList,
  [schemaItemTypes.COLUMN_LIST]: getList,
  [schemaItemTypes.ROLES_LIST]: getList,
  [schemaItemTypes.REQUEST_FIELDS_LIST]: getList,
  [schemaItemTypes.TABLE_COLUMNS]: getList,
  [schemaItemTypes.TEAM_LIST]: getList,
  [schemaItemTypes.IMAGE_LIST]: getList,
  [schemaItemTypes.MANY_UNITS]: getMultiSelectObject,
  [schemaItemTypes.MANY_CALENDAR_UNITS]: getMultiSelectObject,
  [schemaItemTypes.MANY_ROOMS]: getMultiSelectObject,
  [schemaItemTypes.MANY_BUILDINGS]: getMultiSelectObject,
  [schemaItemTypes.MANY_CALENDAR_BUILDINGS]: getMultiSelectObject,
  [schemaItemTypes.MANY_PROJECTS]: getMultiSelectObject,
  [schemaItemTypes.MANY_CALENDAR_PROJECTS]: getMultiSelectObject,
  [schemaItemTypes.MANY_CLIENTS]: getMultiSelectObject,
  [schemaItemTypes.MANY_PERFORMERS]: getMultiSelectObject,
  [schemaItemTypes.MANY_PERFORMERS_FOR_TASK]: getMultiSelectObject,
  [schemaItemTypes.MANY_PERFORMERS_FOR_PROJECT]: getMultiSelectObject,
  [schemaItemTypes.MANY_PAYMENT_TYPE]: getMultiSelectObject,
  [schemaItemTypes.REQUEST_MANY_TYPES]: getMultiSelectObject,
  [schemaItemTypes.REQUEST_MANY_CATEGORY]: getMultiSelectObject,
  [schemaItemTypes.MANY_ROLES]: getMultiSelectObject,
  [schemaItemTypes.MANY_REQUEST_ROLES]: getRequestRolesObject,
  [schemaItemTypes.CREDENTIALS_VARIANT]: getCredentialsObject,
  [schemaItemTypes.FILE_LIST]: getList,
  [schemaItemTypes.SCRIPT_LIST]: getList,
  [schemaItemTypes.EXPANSION_PANEL]: getList,
  [schemaItemTypes.CARD_SECTION]: getCardSection,
  [schemaItemTypes.MONTH_DATE]: getCurrentMonth,
};

const getPropertyFactory = type => PROPERTY_FACTORY_MAP[type] || getNull;
export const initializePropertyFactory = ({ defaultValue, type } = {}) =>
  defaultValue !== undefined ? () => clone(defaultValue) : getPropertyFactory(type);

// eslint-disable-next-line import/prefer-default-export
export function createModelData(schema, withUniqueKey = false) {
  const data = schema.reduce((model, { prop, type, children, defaultValue, min, visibility }) => {
    const visibilityIndex = visibility?.findIndex(property => {
      return property.builderType === FORM && property.value === NONE;
    });

    if (visibilityIndex !== undefined && visibilityIndex !== -1) {
      return model;
    }

    if (prop) {
      const factory = initializePropertyFactory({ type, defaultValue });
      // eslint-disable-next-line no-param-reassign
      model[prop] = factory(type);
    }

    if (children) {
      const partialModel = createModelData(children);

      if (prop) {
        if (Array.isArray(model[prop])) {
          if (typeof min === 'number' && min > 0) {
            const items = Array.from({ length: min }, () => ({ ...clone(partialModel), [UNIQUE_KEY]: uuid() }));
            model[prop].push(...items);
          }
        } else {
          Object.assign(model[prop], partialModel);
        }
      } else {
        Object.assign(model, partialModel);
      }
    }

    return model;
  }, {});

  return withUniqueKey ? { ...data, [UNIQUE_KEY]: uuid() } : data;
}
